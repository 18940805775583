import { pxToRem } from '@fluentsms/agentnet-web-components';
import { FormGroup, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import './CheckBoxGroup.scss';

export type CheckboxGroupProps = {
  label?: string;
  layout?: 'row' | 'twoColumn';
  className?: string;
  required?: boolean;
  removeMarginTop?: boolean;
  children?: any;
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: pxToRem(13),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.125),
  },
  required: {
    color: theme.palette.secondary.dark,
    fontSize: pxToRem(14),
    fontWeight: 600,
  },
  removeMarginTop: {
    marginTop: '-6px',
  },
  formGroup: (props: CheckboxGroupProps) => ({
    display: 'flex',
    flexDirection: props.layout === 'row' ? 'row' : 'column',
    flexWrap: props.layout === 'row' ? 'nowrap' : 'wrap',
  }),
  twoColumnLayout: {
    display: 'grid !important',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1),
  },
}));

export default function AgentNetCheckBoxGroup({
  label,
  layout = 'row',
  className,
  required,
  removeMarginTop,
  children,
}: CheckboxGroupProps): JSX.Element {
  const classes = useStyles({ layout });

  return (
    <FormControl component="fieldset" className={clsx({ [classes.removeMarginTop]: removeMarginTop }, className)}>
      {label && (
        <FormLabel component="legend">
          <Typography variant="body1" className={classes.label}>
            {label} {required && <span className={classes.required}>*</span>}
          </Typography>
        </FormLabel>
      )}
      <FormGroup className={clsx(classes.formGroup, layout === 'twoColumn' && classes.twoColumnLayout)}>
        {children}
      </FormGroup>
    </FormControl>
  );
}
