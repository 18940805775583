export const getFileType = (fileName: string): string => {
  if (!fileName) return '';

  const extension = fileName.split('.').pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return 'pdf';
    case 'xls':
    case 'xlsx':
      return 'excel';
    default:
      return 'file';
  }
};

// Initiates browser download of base64 encoded file with correct MIME type
export const downloadFile = (base64String: string, fileName: string): void => {
  // Determine MIME type based on file extension
  const extension = fileName.split('.').pop()?.toLowerCase();
  let mimeType = 'application/octet-stream'; // Default MIME type

  switch (extension) {
    case 'pdf':
      mimeType = 'application/pdf';
      break;
    case 'doc':
    case 'docx':
      mimeType = 'application/msword';
      break;
    case 'xls':
    case 'xlsx':
      mimeType = 'application/vnd.ms-excel';
      break;
    case 'jpg':
    case 'jpeg':
      mimeType = 'image/jpeg';
      break;
    case 'png':
      mimeType = 'image/png';
      break;
    case 'txt':
      mimeType = 'text/plain';
      break;
  }

  const blob = base64ToBlob(base64String, mimeType);
  const url = URL.createObjectURL(blob);

  // Create temporary link element to trigger file download
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Clean up the URL object to prevent memory leaks
  setTimeout(() => URL.revokeObjectURL(url), 100);
};

// Helper function to convert base64 string back to Blob for browser download
const base64ToBlob = (base64: string, mimeType: string): Blob => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  // Process data in chunks to handle large files efficiently
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString().split(',')[1];
      resolve(base64String || '');
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};
