import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

function TextSnippetOutlined(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} height={25} width={25} viewBox="0 0 25 25">
      <path
        fill={props.htmlColor ? props.htmlColor : `#0074CA`}
        d="M14.17 5 19 9.83V19H5V5zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59M7 15h10v2H7zm0-4h10v2H7zm0-4h7v2H7z"
      ></path>
    </SvgIcon>
  );
}
export default TextSnippetOutlined;
