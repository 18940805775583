import { theme } from '@fluentsms/agentnet-web-components';
import CalculatorIcon from 'ui-kit/icons/Calculator';
import palette from 'ui-kit/theme/palette';

const CalculatorRenderer = (props: {
  tabName: string;
  node: { id: string };
  onClick: () => void;
  isRemitValid: boolean;
}) => (
  <div
    data-testid={`${props?.tabName}Calculator${props?.node?.id}Icon`}
    style={{ height: theme.spacing(2.5) }}
    onClick={props?.onClick}
  >
    {props?.isRemitValid === false && (
      <div
        style={{
          position: 'absolute',
          left: theme.spacing(2.5),
          width: theme.spacing(1),
          height: theme.spacing(1),
          backgroundColor: palette.error.main,
          borderRadius: '50%',
        }}
      ></div>
    )}
    {CalculatorIcon({ fontSize: 'small' })}
  </div>
);
export default CalculatorRenderer;
