import { FormatDateUtils } from '@agentnet/utils';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import React, { useState } from 'react';
import PDFIcon from 'ui-kit/icons/PDF';
import TextSnippetOutlinedIcon from 'ui-kit/icons/TextSnippetOutlined';

type BackTitleListProps = {
  rowData: any[];
  fetchDocument: (policyType: string, documentDescription: string, docGuid: string) => void;
};

const BackTitleList: React.FC<BackTitleListProps> = ({ rowData, fetchDocument }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
      marginTop: pxToRem(114),
      width: '100%',
    },
    height: {
      height: pxToRem(400),
    },
    pt2: {
      paddingTop: theme.spacing(2),
      marginTop: 0,
    },
    marginFileView: {
      marginTop: 0,
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    noResults: {
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: theme.spacing(4),
    },
    backTitleGridContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    gridIcons: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      '& > div': {
        width: '40px',
      },
    },
  }));

  const classes = useStyles();
  const allColumnIds = useState([]);
  const getStartOfDay = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const columnDefs: ColDef[] = [
    {
      field: 'DocumentDescription',
      headerName: 'Document Name',
      tooltipField: 'DocumentDescription',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
    },
    {
      field: 'DocumentType',
      headerName: 'Doc. Type',
      tooltipField: 'DocumentType',
      filter: 'agSetColumnFilter',
      minWidth: 180,
    },
    {
      field: 'PropertyAddress',
      headerName: 'Property Address',
      tooltipField: 'PropertyAddress',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      minWidth: 260,
    },
    {
      field: 'Owners',
      headerName: 'Last Name/Entity',
      tooltipField: 'Owners',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      minWidth: 180,
    },
    {
      field: 'CondoSub',
      headerName: 'Condo/Subdivision',
      tooltipField: 'CondoSub',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      minWidth: 180,
    },
    {
      field: 'UnitLot',
      headerName: 'Unit/Lot',
      tooltipField: 'UnitLot',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      minWidth: 180,
    },
    {
      field: 'PolicyEffectiveDate',
      headerName: 'Order Date',
      tooltipField: 'PolicyEffectiveDate',
      minWidth: 240,
      filter: 'agDateColumnFilter',
      filterParams: {
        type: 'date',
        qaAttribute: 'BackTitleOrderDate',
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
          const cellDate = getStartOfDay(cellValue);
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
            return -1;
          }
          if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
            return 1;
          }
          return 0;
        },
        browserDatePicker: true,
        dateFormat: 'yyyy-mm-dd',
        inRangeInclusive: true,
      },
      valueFormatter: (params) =>
        params.value && params.value !== '0001-01-01T00:00:00' ? FormatDateUtils.MonthDateYear(params.value) : '',
    },
    {
      field: 'Actions',
      headerName: '',
      minWidth: 80,
      maxWidth: 80,
      filter: false,
      floatingFilter: false,
      sortable: false,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <div className={classes.gridIcons}>
            <div>
              {params.data.DocumentType != 'Legal Description' && (
                <Tooltip title="View Document">
                  <div>
                    <PDFIcon
                      fontSize="small"
                      onClick={() => {
                        fetchDocument(params.data.PolicyType, params.data.DocumentDescription, params.data.DocGuid);
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
            <div>
              {((params.data.PolicyType === 'TRD' && params.data.HasLegalDescription) ||
                params.data.DocumentType === 'Legal Description') && (
                <Tooltip title="Legal Description">
                  <div>
                    <TextSnippetOutlinedIcon
                      fontSize="small"
                      onClick={() => {
                        fetchDocument('Legal Desc', params.data.DocumentDescription, params.data.DocGuid);
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
      pinned: 'right',
      suppressMovable: true,
    },
  ];

  const autoSizeGrid = (params: any) => {
    params.api.getColumns().forEach(function (column: { colId: any }) {
      allColumnIds.push(column.colId);
    });
    params.api.autoSizeColumns(allColumnIds);
  };

  const gridRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className="full-width">
      <div>
        {rowData?.length > 0 && (
          <div className={classes.backTitleGridContainer} style={{ width: '100%', height: '100%' }}>
            <div className="ag-theme-alpine table-grid back-title-grid" ref={gridRef} style={{ position: 'relative' }}>
              <DataTable
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={{
                  cellClass: 'left-align-column',
                  headerClass: 'left-align-header',
                  sortable: true,
                  filter: true,
                  floatingFilter: true,
                  filterParams: { closeOnApply: true, suppressAndOrCondition: true },
                  suppressHeaderMenuButton: true,
                  resizable: true,
                  flex: 1,
                }}
                onFirstDataRendered={autoSizeGrid}
                domLayout="autoHeight"
                fixedHeader
                fixedBottomScroll
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const areEqual = (prevProps: BackTitleListProps, nextProps: BackTitleListProps) => {
  return prevProps.rowData === nextProps.rowData;
};

export default React.memo(BackTitleList, areEqual);
