import { useAuth } from '@agentnet/auth';
import {
  CellLink,
  CellStatus,
  Content,
  DashboardMetricCard,
  DataTable,
  PageHeader,
  palette,
} from '@fluentsms/agentnet-web-components';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  CellClickedEvent,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { AgGridReactProps } from 'ag-grid-react';
import { getFirmDetails } from 'api/onBehalfOf/api';
import { getAllOrdersApi, getLockboxNumberApi } from 'api/orderManagement/order-management-api';
import { KBLinks } from 'features/constants';
import { filterParams, formatDate, numberValueFormatter } from 'features/Remittance/reportsListConfig';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import useSessionStorage from 'hooks/useSessionStorage';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { CalendarIcon, LockboxIcon } from 'ui-kit/icons/OrderManagement';
import AgentNetDropdownSelector from 'ui-kit/inputs/AgentNetDropdownSelector';
import { convertDatePeriodToDateRange, dateTooltipValue } from 'utilities/utilities';
import { AllOrdersItemType, AllOrdersRequestType, LockboxNumberType, OrderType } from './types';

export interface ReportsListInterface {
  contentClassName?: string;
}

export const dateFilters = [
  { name: 'Today', value: 'today' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'This Week', value: 'thisWeek' },
  { name: 'Last Week', value: 'lastWeek' },
  { name: 'This Month', value: 'thisMonth' },
  { name: 'Last Month', value: 'lastMonth' },
  { name: 'Last 7 Days', value: 'last7Days' },
  { name: 'Last 30 Days', value: 'last30Days' },
  { name: 'Last 90 Days', value: 'last90Days' },
  { name: 'Custom (Coming Soon)', value: 'custom' },
];

interface DashboardProps {
  pageHeader?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .ag-center-cols-viewport': {
        minHeight: 220,
      },
    },
    topFilter: {
      width: theme.spacing(2),
    },
    topFilters: {
      height: theme.spacing(2),
    },
    noRows: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 220,
      justifyContent: 'center',
    },
    filterIcon: {
      fontSize: 24,
    },
  }),
);

const Dashboard = ({ pageHeader }: DashboardProps) => {
  const classes = useStyles();
  const history = useHistory();
  const gridApiRef = useRef<GridApi<AllOrdersItemType> | null>(null);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const dataQaPrefix = 'OrderManagement-AllOrders';

  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const { addSnackbarMessage } = useSnackBars();
  const { setUserFirm } = profileCtx;

  const [lockbox, setLockbox] = useSessionStorage('lockboxData');
  const [bookedOrder, setBookedOrder] = useSessionStorage('bookOrderData');
  const [savedFilters, setSavedFilters] = useSessionStorage('savedFilters');

  const [lockboxList, setLockboxList] = useState<Array<LockboxNumberType>>([]);
  const [lockboxFilter, setLockboxFilter] = useState<number>(0);
  const [dateFilter, setDateFilter] = useState<string>('today');
  const [allOrders, setAllOrders] = useState<Array<AllOrdersItemType>>([]);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [totalNet, setTotalNet] = useState<number>(0);

  const lockboxFilterChanged = useCallback((lockboxId: number, datePeriod: string) => {
    setLockboxFilter(lockboxId);
    setSavedFilters({ lockboxId: lockboxId, datePeriod: datePeriod });
    executeGetAllOrders(datePeriod, lockboxId);
  }, []);

  const dateFilterChanged = useCallback((lockboxId: number, datePeriod: string) => {
    setDateFilter(datePeriod);
    setSavedFilters({ lockboxId: lockboxId, datePeriod: datePeriod });
    executeGetAllOrders(datePeriod, lockboxId);
  }, []);

  const onGridReady = (params: GridReadyEvent<AllOrdersItemType>) => {
    gridApiRef.current = params.api;
  };

  const onFilterChanged = (): void => {
    setDisplayedRowCount(getDisplayedRowCount());
  };

  const getDisplayedRowCount = () => {
    if (gridApiRef.current) return gridApiRef.current.getDisplayedRowCount();
    return 0;
  };

  const getAllOrders = async (dateFilter?: string, lockboxId?: number): Promise<Array<AllOrdersItemType>> => {
    const dateRange = convertDatePeriodToDateRange(dateFilter);
    const payload: AllOrdersRequestType = {
      fromOrderDate: dateRange.from,
      toOrderDate: dateRange.to,
      lockboxId: lockboxId,
    };
    const token = await getAccessToken();
    const response = await getAllOrdersApi(payload, token);
    return response;
  };

  const getLockboxList = async (): Promise<Array<LockboxNumberType>> => {
    if (lockbox) return lockbox;
    const token = await getAccessToken();
    const response = await getLockboxNumberApi(token);
    setLockbox(response);
    return response;
  };

  const fetchLockboxNumbers = async () => {
    const result = await getLockboxList();
    setLockboxList([{ name: 'All Lockboxes', value: 0 }, ...result]);
  };

  const goToOrderDetails = async (row: AllOrdersItemType) => {
    const token = await getAccessToken();
    const firmDetailsResults = await getFirmDetails(token, String(row.firmId));
    if (firmDetailsResults) {
      window.localStorage.setItem('userFirm', JSON.stringify(firmDetailsResults));
      setUserFirm && setUserFirm(firmDetailsResults);
      const myOrder: OrderType = {
        firmId: row.firmId,
        lockboxId: row.lockboxId,
        orderId: row.orderId,
        orderStatus: row.orderStatus,
        orderDate: row.orderDate,
        referenceNumber: row.referenceNumber,
        checkCount: row.checkCount,
        reportedAmount: row.orderNet,
        targetTab: 'orderQueue',
        firm: {
          firmId: row.firmId,
          firmName: row.firmName,
          isActive: true,
        },
        lockboxNumber: {
          name: lockboxList.find((l) => l.value === row.lockboxId)?.name ?? '',
          value: row.lockboxId,
        },
      };

      if (row.orderStatus === 'Closed') {
        history.push('/order-management/closed-order-details', { order: myOrder });
      } else {
        history.push('/order-management', { order: myOrder });
      }
    }
  };

  const {
    execute: executeGetAllOrders,
    status: executeGetAllOrdersStatus,
    value: getAllOrdersValue,
    errors: getAllOrdersErrors,
  } = useAsync<Array<AllOrdersItemType>>(getAllOrders, false);

  const CustomOverlayLoading = () => {
    return (
      <div className={classes.noRows}>
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const agGridConfig: AgGridReactProps = {
    rowData: allOrders ?? [],
    columnDefs: [
      {
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) => {
          return <CellStatus status={params.value} colorsByStatus={{ 'Book Error': palette.error.main }} />;
        },
        field: 'orderStatus',
        filter: 'agSetColumnFilter',
        minWidth: 144,
        maxWidth: 256,
        tooltipField: 'orderStatus',
      },
      {
        headerName: 'Order ID',
        field: 'orderId',
        filter: 'agTextColumnFilter',
        minWidth: 144,
        maxWidth: 256,
        tooltipField: 'orderId',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRenderer: CellLink,
        cellRendererParams: (row: ValueFormatterParams) => {
          return row.data.orderStatus === 'Closed'
            ? { linkTemplate: 'order-management/closed-order-details' }
            : { linkTemplate: 'order-management' };
        },
        onCellClicked: (event: CellClickedEvent) => {
          goToOrderDetails(event.data);
        },
        sort: 'desc',
      },
      {
        headerName: 'Order Number',
        field: 'orderNumbers',
        filter: 'agTextColumnFilter',
        minWidth: 144,
        tooltipField: 'orderNumbers',
        cellRenderer: (params: ICellRendererParams) => {
          return params.value.length > 0 ? params.value.join(', ') : '';
        },
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Firm',
        field: 'firmName',
        filter: 'agSetColumnFilter',
        minWidth: 256,
        tooltipField: 'firmName',
      },
      {
        headerName: 'Batch/Ticket Reference Number',
        field: 'referenceNumber',
        filter: 'agTextColumnFilter',
        minWidth: 144,
        maxWidth: 384,
        tooltipField: 'referenceNumber',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Order Date',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'orderDate',
        minWidth: 144,
        maxWidth: 256,
        cellRenderer: (params: ICellRendererParams) => {
          return params?.value?.length > 0 ? formatDate(params.value) : '';
        },
        tooltipValueGetter: dateTooltipValue,
      },
      {
        headerName: 'Date Closed',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'closedDate',
        minWidth: 144,
        maxWidth: 256,
        cellRenderer: (params: ICellRendererParams) => {
          return params?.value?.length > 0 ? formatDate(params.value) : '-';
        },
        tooltipValueGetter: dateTooltipValue,
      },
      {
        headerName: 'Number of Files',
        type: 'rightAligned',
        cellStyle: { 'justify-content': 'flex-end' },
        field: 'fileCount',
        filter: 'agNumberColumnFilter',
        minWidth: 144,
        maxWidth: 256,
        tooltipField: 'fileCount',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Order Net',
        type: 'rightAligned',
        cellStyle: { 'justify-content': 'flex-end' },
        field: 'orderNet',
        filter: 'agNumberColumnFilter',
        minWidth: 144,
        maxWidth: 256,
        tooltipField: 'orderNet',
        valueFormatter: numberValueFormatter,
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
    ],
    maintainColumnOrder: true,
    gridOptions: {
      getRowId: (params: GetRowIdParams<AllOrdersItemType>) => String(params.data.orderId),
      suppressRowClickSelection: true,
    },
    defaultColDef: {
      flex: 1,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
      suppressSizeToFit: false,
      width: 144,
      minWidth: 144,
    },
    domLayout: 'autoHeight',
    onGridReady: onGridReady,
    onFilterChanged: onFilterChanged,
  };

  useEffect(() => {
    bookedOrder &&
      addSnackbarMessage({
        message: `Order Id ${bookedOrder.orderId} Booked`,
        type: 'success',
      });
    setBookedOrder(null);
    fetchLockboxNumbers();
    if (savedFilters) {
      setLockboxFilter(savedFilters.lockboxId);
      setDateFilter(savedFilters.datePeriod);
      executeGetAllOrders(savedFilters.datePeriod, savedFilters.lockboxId);
    } else {
      executeGetAllOrders();
    }
  }, []);

  useEffect(() => {
    if (executeGetAllOrdersStatus === 'success') {
      setAllOrders(getAllOrdersValue ?? []);
      setTotalFiles(getAllOrdersValue?.reduce((acc, curr) => acc + curr.fileCount, 0) ?? 0);
      setTotalNet(getAllOrdersValue?.reduce((acc, curr) => acc + curr.orderNet, 0) ?? 0);
    }
    if (executeGetAllOrdersStatus === 'error') {
      getAllOrdersErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeGetAllOrdersStatus]);

  useEffect(() => {
    setDisplayedRowCount(getDisplayedRowCount());
  }, [displayedRowCount]);

  if (!getAllOrdersValue) {
    return (
      <ContentContainer fullWidth>
        <Content>
          <CustomOverlayLoading />
        </Content>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer fullWidth>
      <Content className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader
              divider={true}
              menuItems={[
                {
                  label: 'Knowledge Base',
                  link: KBLinks.home,
                },
              ]}
              title={pageHeader || 'Order Management'}
              contentRight={
                <div style={{ display: 'flex' }}>
                  <AgentNetDropdownSelector
                    name="lockboxFilter"
                    data-testid={`${dataQaPrefix}-Lockbox-Filter`}
                    options={lockboxList ?? []}
                    value={lockboxFilter}
                    id="lockboxFilter"
                    dropdowntype="outlined"
                    menuOption={(value: number) => {
                      lockboxFilterChanged(value, dateFilter);
                      return false;
                    }}
                    className={classes.topFilters}
                    primaryColor
                    prefixIcon={<LockboxIcon />}
                  />
                  <span className={classes.topFilter} />
                  <AgentNetDropdownSelector
                    name="dateFilter"
                    data-testid={`${dataQaPrefix}-Date-Filter`}
                    options={dateFilters ?? []}
                    value={dateFilter}
                    id="dateFilter"
                    dropdowntype="outlined"
                    menuOption={(value: string) => {
                      dateFilterChanged(lockboxFilter, value);
                      return false;
                    }}
                    className={classes.topFilters}
                    primaryColor
                    prefixIcon={<CalendarIcon />}
                  />
                </div>
              }
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <DashboardMetricCard
              dataTestId={`${dataQaPrefix}-Card-Orders`}
              heading="Orders"
              value={String(getAllOrdersValue.length)}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <DashboardMetricCard dataTestId={`${dataQaPrefix}-Card-Files`} heading="Files" value={String(totalFiles)} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <DashboardMetricCard
              dataTestId={`${dataQaPrefix}-Card-Net`}
              heading="Order Net Total"
              value={String(numberValueFormatter(totalNet, true))}
            />
          </Grid>
          <Grid item xs={12}>
            <DataTable {...agGridConfig} fixedHeader fixedBottomScroll />
            {getAllOrdersValue && (
              <Typography variant="body2">
                Showing {displayedRowCount || getAllOrdersValue.length} of {getAllOrdersValue.length} orders
              </Typography>
            )}
          </Grid>
        </Grid>
      </Content>
    </ContentContainer>
  );
};

export default Dashboard;
