import { CurrencyField } from 'ui-kit/inputs';
import { makeStyles, Box, Grid } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { AgentNetDropdownSelector } from 'ui-kit/inputs';
import DateFieldString from 'ui-kit/inputs/DateField/DateFieldString';
import { PolicyInventoryType } from './types';
import { useEffect, useState } from 'react';
import AutocompletePolicySearch, { searchType } from 'ui-kit/inputs/AutocompletePolicySearch/AutocompletePolicySearch';
import { CreateNewFileJacketData } from './CreateNewFileDrawer';
import { FieldValidationError } from 'utilities/validation/validation';
import { useAuth } from '@agentnet/auth';
import { getRateTypes } from 'api/jacket-api';
import { RateTypes } from 'features/jackets/types';
import useAsync from 'hooks/useAsync';
import { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import { formatDate } from 'utilities/utilities';

interface CreateNewFileJacketProps {
  key: number;
  accountNumber: string;
  stateCode: string;
  countyCode: string;
  policies: PolicyInventoryType[];
  jacket: CreateNewFileJacketData;
  onChange: (key: number, jacket: CreateNewFileJacketData) => void;
  validationErrors: FieldValidationError[] | undefined;
}

function findPolicy(
  input: string | undefined,
  stateCode: string,
  policies: PolicyInventoryType[],
): PolicyInventoryType | null {
  if (!input) {
    return null;
  }
  for (const policy of policies) {
    if (!policy.stateList.includes(stateCode)) {
      continue;
    }
    for (const policyNumber of policy.policyNumbers) {
      if (policyNumber === input) {
        return policy;
      }
    }
  }
  return null;
}

function getSearchPolicies(
  input: string | undefined,
  stateCode: string,
  policies: PolicyInventoryType[],
): searchType[] {
  const searchTypes: searchType[] = [];
  for (const policy of policies) {
    if (!policy.stateList.includes(stateCode)) {
      continue;
    }
    for (const policyNumber of policy.policyNumbers) {
      if (!input || policyNumber.includes(input)) {
        searchTypes.push({
          name: policyNumber,
          value: policyNumber,
          display: policy.itemDescription,
          isActive: true,
        });
      }
    }
  }
  return searchTypes;
}

function getMaxDateString(): string | undefined {
  const d = new Date();
  return (
    (d.getMonth() + 1).toString().padStart(2, '0') +
    '/' +
    d.getDate().toString().padStart(2, '0') +
    '/' +
    d.getFullYear()
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      border: '1px solid #c4c4c4',
      borderRadius: '6px',
      width: '97.5%',
      marginLeft: 10,
      marginBottom: 10,
      paddingBottom: 10,
    },
    jacketContainer: {
      marginLeft: 10,
      marginTop: 10,
      width: '98%',
    },
    jacketBox: {
      backgroundColor: '#f3fbff',
      fontSize: 14,
      paddingTop: 10,
      paddingLeft: 20,
      paddingBottom: 10,
      borderBottom: '1px solid #c4c4c4',
      borderRadius: '6px',
    },
    jacketLabel: {
      fontWeight: 500,
    },
    jacketPolicyLabel: {
      fontSize: 12,
      marginTop: 5,
    },
  }),
);

export function CreateNewFileJacket({
  key,
  accountNumber,
  stateCode,
  countyCode,
  policies,
  jacket,
  onChange,
  validationErrors,
}: CreateNewFileJacketProps): JSX.Element {
  const { getAccessToken } = useAuth();
  const [searchPolicyNumberInput, setSearchPolicyNumberInput] = useState<string>();
  const [policy, setPolicy] = useState<PolicyInventoryType | null>(null);
  const [policyDate, setPolicyDate] = useState<string>();
  const [rateTypesSelections, setRateTypesSelections] = useState<SelectOption[]>([]);
  const classes = useStyles();

  const requestRateTypes = async (): Promise<RateTypes[]> => {
    const token = await getAccessToken();
    return await getRateTypes(
      policy?.itemNo,
      stateCode,
      jacket.policyDate,
      false,
      countyCode,
      Number(accountNumber),
      token,
    );
  };

  const {
    execute: executeGetRateTypes,
    status: getRateTypesStatus,
    errors: getRateTypesErrors,
    value: getRateTypesValue,
  } = useAsync<RateTypes[]>(requestRateTypes, false);

  useEffect(() => {
    if (policy && policyDate) {
      executeGetRateTypes();
    }
  }, [policy, policyDate]);

  useEffect(() => {
    if (getRateTypesStatus === 'success' && getRateTypesValue) {
      setRateTypesSelections(
        getRateTypesValue.map((rateType) => {
          return {
            value: rateType.rateTypeId,
            name: rateType.rateTypeName,
          };
        }),
      );
    } else if (getRateTypesStatus === 'error') {
      console.error(getRateTypesErrors);
    }
  }, [getRateTypesStatus]);

  return (
    <Box className={classes.box}>
      {policy && (
        <>
          <div className={classes.jacketBox}>
            <div className={classes.jacketLabel}>{policy?.itemDescription}</div>
            <div className={classes.jacketPolicyLabel}>Policy No: {jacket.policyNumber}</div>
          </div>
        </>
      )}
      <Grid key={key} container spacing={2} className={classes.jacketContainer}>
        <Grid item sm={6}>
          <AutocompletePolicySearch
            label="Policy Number"
            name="policyNumberSearchInput"
            hideSearchIcon
            onChange={(policyNumber) => {
              const input = policyNumber.target?.value;
              setSearchPolicyNumberInput(input);

              if (typeof policyNumber === 'string') {
                const foundPolicy = findPolicy(policyNumber, stateCode, policies);
                if (foundPolicy != policy) {
                  setPolicy(foundPolicy);
                  jacket.policyNumber = policyNumber;
                  onChange(key, jacket);
                }
              }
            }}
            options={getSearchPolicies(searchPolicyNumberInput, stateCode, policies)}
            required
            showValidation
            error={validationErrors?.some((error) => error.field === 'policyNumber')}
            errs={validationErrors}
            helperText={
              validationErrors?.some((error) => error.field === 'policyNumber') && 'Policy Number is required'
            }
          />
        </Grid>
        <Grid item sm={6}></Grid>
        <Grid item sm={3}>
          <CurrencyField
            onBlur={(event) => {
              let liabilityAmount = 0;
              if (event.target.value) {
                liabilityAmount = Number(event.target.value.replaceAll(',', ''));
              }
              if (liabilityAmount != jacket.liabilityAmount) {
                jacket.liabilityAmount = liabilityAmount;
                onChange(key, jacket);
              }
            }}
            fullWidth
            label={'Liability Amount'}
            required
            error={validationErrors?.some((error) => error.field === 'liabilityAmount')}
            errs={validationErrors}
            helperText={
              validationErrors?.some((error) => error.field === 'liabilityAmount') && 'Liability Amount is required'
            }
          />
        </Grid>
        <Grid item sm={3}>
          <DateFieldString
            fullWidth
            value={policyDate}
            maxDate={getMaxDateString()}
            label={'Policy Date'}
            onChange={(policyDate) => {
              let formattedPolicyDate = policyDate ? formatDate(policyDate || '') || '' : '';
              if (new Date(formattedPolicyDate) > new Date()) {
                formattedPolicyDate = '';
              }
              if (formattedPolicyDate !== jacket.policyDate) {
                setPolicyDate(formattedPolicyDate);
                jacket.policyDate = formattedPolicyDate;
                onChange(key, jacket);
              }
            }}
            required
            showValidation
            error={validationErrors?.some((error) => error.field === 'policyDate')}
            errs={validationErrors}
          />
        </Grid>
        <Grid item sm={3}>
          <CurrencyField
            onBlur={(event) => {
              let reportedGross = 0;
              if (event.target.value) {
                reportedGross = Number(event.target.value.replaceAll(',', ''));
              }
              if (reportedGross != jacket.reportedGross) {
                jacket.reportedGross = reportedGross;
                onChange(key, jacket);
              }
            }}
            fullWidth
            label={'Reported Gross'}
            required
            error={validationErrors?.some((error) => error.field === 'reportedGross')}
            errs={validationErrors}
            helperText={
              validationErrors?.some((error) => error.field === 'reportedGross') && 'Reported Gross is required'
            }
          />
        </Grid>
        <Grid item sm={3}>
          <CurrencyField
            onBlur={(event) => {
              let reportedNet = 0;
              if (event.target.value) {
                reportedNet = Number(event.target.value.replaceAll(',', ''));
              }
              if (reportedNet != jacket.reportedNet) {
                jacket.reportedNet = reportedNet;
                onChange(key, jacket);
              }
            }}
            fullWidth
            label={'Reported Net'}
            required
            error={validationErrors?.some((error) => error.field === 'reportedNet')}
            errs={validationErrors}
            helperText={validationErrors?.some((error) => error.field === 'reportedNet') && 'Reported Net is required'}
          />
        </Grid>
        <Grid item sm={3}>
          <AgentNetDropdownSelector
            label={'Rate Type'}
            name={'Rate Type'}
            qaAttribute={'RateType'}
            options={rateTypesSelections}
            value={jacket.rateTypeId}
            menuOption={(rateTypeId) => {
              jacket.rateTypeId = rateTypeId;
              onChange(key, jacket);
            }}
            required
            error={validationErrors?.some((error) => error.field === 'rateTypeId')}
            errs={validationErrors}
          />
        </Grid>
        {/* <Grid item sm={3}>
          <AgentNetDropdownSelector
            label={'Stat Code'}
            name={'Stat Code'}
            qaAttribute={'StatCode'}
            required
            error={validationErrors?.some((error) => error.field === 'statCode')}
            errs={validationErrors}
          />
        </Grid> */}
        <Grid item sm={3}>
          <CurrencyField
            onBlur={(event) => {
              let priorPolicyAmount = 0;
              if (event.target.value) {
                priorPolicyAmount = Number(event.target.value.replaceAll(',', ''));
              }
              if (priorPolicyAmount !== jacket.priorPolicyAmount) {
                jacket.priorPolicyAmount = priorPolicyAmount;
                onChange(key, jacket);
              }
            }}
            fullWidth
            label={'Prior Liability Amount'}
          />
        </Grid>
        <Grid item sm={3}>
          <DateFieldString
            fullWidth
            maxDate={getMaxDateString()}
            label={'Prior Policy Date'}
            value={jacket.priorPolicyDate}
            onChange={(priorPolicyDate) => {
              let formattedPriorPolicyDate = priorPolicyDate ? formatDate(priorPolicyDate || '') || '' : '';
              if (new Date(formattedPriorPolicyDate) > new Date()) {
                formattedPriorPolicyDate = '';
              }
              if (formattedPriorPolicyDate !== jacket.policyDate) {
                jacket.priorPolicyDate = formattedPriorPolicyDate;
                onChange(key, jacket);
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
