import * as yup from 'yup';

export const createNewFileTypeSchema = yup.object().shape({
  accountNumber: yup.string().required(),
  fileNumber: yup.string().required(),
  naicPropertyType: yup.string().required(),
  stateCode: yup.string().required(),
  countyCode: yup.string().required(),
});

export const createNewFileJacketTypeSchema = yup.object().shape({
  policyNumber: yup.string().required(),
  liabilityAmount: yup.number().required(),
  policyDate: yup.string().required(),
  reportedGross: yup.number().required(),
  reportedNet: yup.number().required(),
  rateTypeId: yup.number().required().moreThan(0),
});
