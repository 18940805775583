import { useAuth } from '@agentnet/auth';
import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import { enqueueFilesApi, getTeamUsers } from 'api/orderManagement/order-management-api';
import {
  FileStatus,
  FileStatusColor,
  getKeyByValue,
  getMemberNames,
  ReasonCode,
  TeamAssignment,
} from 'features/orderManagement/enum';
import {
  FileEditAssignmentType,
  OrderFileQueueResponseType,
  OrderFilesRequestType,
  UserDetailType,
} from 'features/orderManagement/types';
import useAsync from 'hooks/useAsync';
import { useEffect, useState } from 'react';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { AgentNetDropdownSelector } from 'ui-kit/inputs';
import { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import { getFullName } from 'utilities/utilities';

interface FileEditAssignmentProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  data: FileEditAssignmentType[];
  showTeams: boolean;
}

function firstOrDefault<T>(data: T[]): T | null {
  return data?.[0] || null;
}

function getFileStatus(data: FileEditAssignmentType[]): FileStatus | null {
  const first = firstOrDefault(data)?.status;
  return first && data?.every((datum) => datum.status === first) ? FileStatus[first as keyof typeof FileStatus] : null;
}

function getFileReasonCode(data: FileEditAssignmentType[]): ReasonCode | null {
  const first = firstOrDefault(data)?.reasonCode;
  return first && data?.every((datum) => datum.reasonCode === first)
    ? ReasonCode[first as keyof typeof ReasonCode]
    : null;
}

function getFileTeam(data: FileEditAssignmentType[]): TeamAssignment | null {
  const first = firstOrDefault(data)?.assignedToGroup;
  return first && data?.every((datum) => datum.assignedToGroup === first)
    ? TeamAssignment[first as keyof typeof TeamAssignment]
    : null;
}

function getFileAssigneeId(data: FileEditAssignmentType[]): number | null {
  const first = firstOrDefault(data)?.caseFileOwnerId;
  return first && data?.every((datum) => datum.caseFileOwnerId === first) ? first : null;
}

function getFileAssigneeName(data: FileEditAssignmentType[]): string | null {
  const first = firstOrDefault(data)?.fullName;
  return first && data?.every((datum) => datum.fullName === first) ? first : null;
}

function getFileAssigneeUsername(data: FileEditAssignmentType[]): string | null {
  const first = firstOrDefault(data)?.assignedTo;
  return first && data?.every((datum) => datum.assignedTo === first) ? first : null;
}

export const FileEditAssignmentContainer = ({
  open,
  setOpen,
  data,
  showTeams,
}: FileEditAssignmentProps): JSX.Element => {
  const fileStatus = getFileStatus(data);
  const fileReasonCode = getFileReasonCode(data);
  const fileTeam = getFileTeam(data);

  const [status, setStatus] = useState<FileStatus | null>();
  const [reasonCode, setReasonCode] = useState<ReasonCode | null>();
  const [team, setTeam] = useState<TeamAssignment | null>();
  const [assigneeId, setAssigneeId] = useState<number | null>(getFileAssigneeId(data));
  const [assigneeName, setAssigneeName] = useState<string | null>(getFileAssigneeName(data));
  const [assigneeUsername, setAssigneeUsername] = useState<string | null>(getFileAssigneeUsername(data));
  const [teamMembers, setTeamMembers] = useState<UserDetailType[]>([]);

  const { getAccessToken } = useAuth();

  const useStyles = makeStyles(() =>
    createStyles({
      routeContainer: {
        width: '100%',
      },
      circle: {
        width: 12,
        height: 12,
        display: 'inline-block',
        marginRight: 4,
        borderRadius: 12,
      },
      separator: {
        paddingBottom: 20,
      },
    }),
  );

  const classes = useStyles();

  const getTeamSelectOptions = (): SelectOption[] => {
    const teamSelectOptions: SelectOption[] = [];
    const teamAssignments = getMemberNames(TeamAssignment);
    let teamAssignment = teamAssignments.next();
    while (!teamAssignment.done) {
      teamSelectOptions.push({
        value: TeamAssignment[teamAssignment.value as keyof typeof TeamAssignment],
        name: teamAssignment.value,
      });
      teamAssignment = teamAssignments.next();
    }
    return teamSelectOptions;
  };

  const getAssigneeSelectOptions = (): SelectOption[] => {
    const assigneeSelectOptions: SelectOption[] = [];
    for (const teamMember of teamMembers) {
      const fullName = getFullName(teamMember);
      assigneeSelectOptions.push({
        value: teamMember.userId,
        name: `${fullName}`,
        tooltip: teamMember.username,
      });
    }
    return assigneeSelectOptions;
  };

  const getReasonCodeSelectOptions = (): SelectOption[] => {
    const reasonCodesSelectOptions: SelectOption[] = [];
    const reasonCodes = getMemberNames(ReasonCode);
    let reasonCode = reasonCodes.next();
    while (!reasonCode.done) {
      reasonCodesSelectOptions.push({
        value: ReasonCode[reasonCode.value as keyof typeof ReasonCode],
        name: reasonCode.value,
      });
      reasonCode = reasonCodes.next();
    }
    return reasonCodesSelectOptions;
  };

  const getFileStatusMenuItems = (): JSX.Element[] => {
    const fileStatusMenuItems: JSX.Element[] = [];
    const fileStatuses = getMemberNames(FileStatus);
    let fileStatus = fileStatuses.next();
    while (!fileStatus.done) {
      const fileStatusKey = fileStatus.value;
      const fileStatusValue = FileStatus[fileStatusKey as keyof typeof FileStatus];
      const fileStatusColor = FileStatusColor[fileStatusKey as keyof typeof FileStatusColor];
      const menuItem = (
        <MenuItem key={fileStatusKey} value={fileStatusValue}>
          <span
            className={classes.circle}
            style={{ backgroundColor: '#' + fileStatusColor.toString(16).padStart(6, '0') }}
          ></span>
          {fileStatusKey}
        </MenuItem>
      );
      fileStatusMenuItems.push(menuItem);
      fileStatus = fileStatuses.next();
    }
    return fileStatusMenuItems;
  };

  const getStatus = () => status || fileStatus;
  const getTeam = () => team || fileTeam;
  const getReasonCode = () => reasonCode || fileReasonCode;
  const getAssigneeId = () => assigneeId;
  const getAssigneeName = () => assigneeName;
  const getAssigneeUsername = () => assigneeUsername;

  const enqueueFiles = async (): Promise<OrderFileQueueResponseType> => {
    const token = await getAccessToken();
    const first = firstOrDefault(data);
    const orderId = first?.orderId ?? 0;
    const payload: OrderFilesRequestType = {
      orderId: orderId,
      fileIds: data.map((datum) => datum.fileId),
      fileStatusId: getStatus() ?? 0,
      reasonCodeId: getReasonCode() ?? 0,
      fscRoleId: getTeam(),
      userId: getAssigneeId(),
    };
    return await enqueueFilesApi(orderId, payload, token);
  };

  const getTeamMembers = async (): Promise<Array<UserDetailType>> => {
    const fscRoleId = getTeam();
    if (fscRoleId) {
      const token = await getAccessToken();
      const teamMembers = await getTeamUsers(Number(fscRoleId), token);
      return teamMembers;
    }
    return [];
  };

  const {
    execute: executeEnqueueFiles,
    status: enqueueFilesStatus,
    errors: enqueueFilesErrors,
  } = useAsync<OrderFileQueueResponseType>(enqueueFiles, false);

  const {
    execute: executeGetTeamMembers,
    status: getTeamMembersStatus,
    value: getTeamMembersValue,
  } = useAsync<Array<UserDetailType>>(getTeamMembers, false);

  const onConfirm = () => {
    executeEnqueueFiles();
  };

  const onDismissAction = () => {
    setStatus(null);
    setTeam(null);
    setAssigneeId(null);
    setAssigneeName(null);
    setAssigneeUsername(null);
    setReasonCode(null);
    setOpen(false);
  };

  useEffect(() => {
    if (getTeam()) {
      executeGetTeamMembers();
    }
  }, [team, fileTeam]);

  useEffect(() => {
    if (enqueueFilesStatus === 'success') {
      if (data) {
        for (const datum of data) {
          datum.assignedToGroup = getKeyByValue(TeamAssignment, getTeam()) ?? '';
          datum.reasonCode = getKeyByValue(ReasonCode, getReasonCode()) ?? '';
          datum.status = getKeyByValue(FileStatus, getStatus()) ?? '';
          datum.caseFileOwnerId = getAssigneeId() ?? 0;
          datum.assignedTo = getAssigneeUsername() ?? '';
          datum.fullName = getAssigneeName() ?? '';
        }
      }
      onDismissAction();
    } else if (enqueueFilesStatus === 'error') {
      console.error(enqueueFilesErrors);
    }
  }, [enqueueFilesStatus]);

  useEffect(() => {
    if (getTeamMembersStatus === 'success' && getTeamMembersValue) {
      setTeamMembers(getTeamMembersValue);
    }
  }, [getTeamMembersStatus]);

  return (
    <AgentNetConfirmationDialog
      open={open}
      dialogTitle="Modify Assignment"
      dialogTextHTML={
        <>
          {showTeams && (
            <div className={classes.separator}>
              <AgentNetDropdownSelector
                label="Team"
                name="Team"
                value={getTeam() || ''}
                options={getTeamSelectOptions()}
                menuOption={(value: any) => {
                  setAssigneeId(null);
                  setAssigneeName(null);
                  setAssigneeUsername(null);
                  setTeam(value);
                }}
              />
            </div>
          )}
          {showTeams && (
            <div className={classes.separator}>
              <AgentNetDropdownSelector
                label="Assignee"
                name="Assignee"
                value={getAssigneeId() || ''}
                title="Select Assignee"
                options={getAssigneeSelectOptions()}
                menuOption={(value: number) => {
                  const user = teamMembers.find((member) => member.userId === value);
                  const name = user ? getFullName(user) : '';
                  setAssigneeId(value);
                  setAssigneeName(name);
                  setAssigneeUsername(user?.username ?? '');
                }}
                hasTooltip={true}
              />
            </div>
          )}
          <div className={classes.separator}>
            <AgentNetDropdownSelector
              label="Status"
              name="Status"
              value={getStatus() || ''}
              required
              customMenuItems={getFileStatusMenuItems()}
              menuOption={(value: any) => {
                setStatus(value);
              }}
            />
          </div>
          <div className={classes.separator}>
            <AgentNetDropdownSelector
              label="Reason Code"
              name="Reason Code"
              value={getReasonCode() || ''}
              options={getReasonCodeSelectOptions()}
              menuOption={(value: any) => {
                setReasonCode(value);
              }}
            />
          </div>
        </>
      }
      dialogBtnContent="Save"
      secondaryActionBtnContent="Cancel"
      onDismissAction={onDismissAction}
      onConfirm={onConfirm}
      disabled={enqueueFilesStatus === 'pending'}
      disableBackDropClick
    />
  );
};
