import { useAuth } from '@agentnet/auth';
import { CellLink, CellMessage, CellStatus, Content, DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { Box, ButtonGroup, Divider, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  ITooltipParams,
  RowNode,
  SortChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import {
  bookOrderApi,
  dequeueFilesApi,
  enqueueFilesApi,
  getAccountInfoApi,
  getFilesApi,
  getOrderFileDetailsApi,
} from 'api/orderManagement/order-management-api';
import clsx from 'clsx';
import { FileEditAssignmentContainer } from 'core/layout-core/core-content/FileContent/FileEditAssignmentContainer';
import ReportsAndPayDrawer from 'core/layout-core/core-content/ReportsAndPayDrawer';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import CalculatorRenderer from 'features/Remittance/CalculatorRenderer';
import useRatesAndFee from 'features/Remittance/useRatesAndFee';
import { useOrderInfo } from 'hooks/OrderInfoContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import useSessionStorage from 'hooks/useSessionStorage';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { getFullName } from 'utilities/utilities';
import { ConversationDrawer } from './ConversationDrawer';
import { CreateNewFileDrawer } from './CreateNewFileDrawer';
import { ActivityRights, FileStatus, getColorsByStatus } from './enum';
import { fileListFilterParams, formatDate, numberValueFormatter, queueFilters } from './fileListConfig';
import { PolicyNumberSearchField } from './PolicyNumberSearchField';
import {
  AccountInfoRequestType,
  AccountInfoType,
  BookedOrderType,
  FileCountsType,
  FileEditAssignmentType,
  FileType,
  GetNotesActionLog,
  OrderFileDetailsType,
  OrderFileQueueResponseType,
  OrderFilesRequestType,
  OrderType,
} from './types';

const colorsByStatus = getColorsByStatus();

const SelectFiles = () => {
  const { getAccessToken } = useAuth();

  const history = useHistory();
  const gridApiRef = useRef<GridApi | null>(null);
  const { addGlobalMsg } = useGlobalMessages();

  const location = useLocation<{ order: OrderType }>();
  const orderData = location.state?.order;

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile, userFirm } = profileCtx;
  const orderId: number = orderData?.orderId || 0;
  const targetTab: string = orderData?.targetTab || '';

  const [queue, setQueue] = useState<Array<FileType>>([]);
  const [, setSessionData] = useSessionStorage('bookOrderData');
  const [allFiles, setAllFiles] = useState<Array<FileType>>([]);
  const [queueFilter, setQueueFilter] = useState<string>('allFiles');
  const [selectedFiles, setSelectedFiles] = useState<Array<FileType>>([]);
  const [selectedQueueFiles, setSelectedQueueFiles] = useState<Array<FileType>>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);
  const [searchPolicyNumbers, setSearchPolicyNumbers] = useState<string>('');
  const [editAssignmentDrawerOpen, setEditAssignmentDrawerOpen] = useState<boolean>(false);
  const [editAssignmentData, setEditAssignmentData] = useState<Array<FileEditAssignmentType>>([]);
  const [createNewFileDrawerOpen, setCreateNewFileDrawerOpen] = useState<boolean>(false);

  const allFilesTabSelected = queueFilter === 'allFiles';
  const orderQueueTabSelected = queueFilter === 'orderQueue';

  const getSelectedRows = (): FileType[] => gridApiRef.current?.getSelectedRows() ?? [];
  const selectedRows = getSelectedRows();

  const setSelectedRowsForTab = (selectedRows: FileType[]) => {
    if (allFilesTabSelected) {
      setSelectedFiles(selectedRows);
    } else if (orderQueueTabSelected) {
      setSelectedQueueFiles(selectedRows);
    }
  };

  const { drawerData, setDrawerData, openDrawer, setOpenDrawer, handleDrawerOpen, rateAndFeeExecute } = useRatesAndFee(
    userFirm?.firmId ?? '',
    'order-management',
  );

  const calculateGrossTotal = (rows: FileType[]): number => {
    return parseFloat(rows.reduce((acc, row) => acc + row.gross, 0).toFixed(2));
  };

  const calculateNetTotal = (rows: FileType[]): number => {
    return parseFloat(rows.reduce((acc, row) => acc + row.net, 0).toFixed(2));
  };

  const calculateNetDifference = (netTotal: number, reportedAmount: number): number => {
    return parseFloat((netTotal - reportedAmount).toFixed(2));
  };

  const dataQaPrefix = 'OrderManagement-SelectFiles';

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      },
      backButton: {
        margin: theme.spacing(2, 0, -3, 3),
      },
      pageHeaderTitle: {
        textTransform: 'uppercase',
      },
      tableDefault: {
        height: `calc(100vh - ${selectedRows?.length ? '367px' : '307px'}) !important`,
      },
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      selectedRows: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100% !important',
        fontSize: pxToRem(14),
        padding: theme.spacing(3),
        paddingBottom: 12,
      },
      selectedRowsActive: {
        marginBottom: '59px',
        paddingBottom: 0,
      },
      noRows: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 220,
        justifyContent: 'center',
      },
      footerWrap: {
        position: 'fixed',
        bottom: 0,
        background: '#E7F1FA',
        borderTop: `1px solid`,
        borderColor: theme.palette.divider,
        width: '100%',
        padding: '11px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: 11,
        left: 0,
      },
      footerActions: {
        '& .MuiButton-root': {
          marginLeft: theme.spacing(2),
        },
      },
      cancelBtn: {
        backgroundColor: theme.palette.brand.feather50,
      },
      createNewFileBtn: {
        marginTop: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
      },
    }),
  );

  const classes = useStyles();

  const getFiles = async (firmId: number): Promise<Array<FileType>> => {
    const token = await getAccessToken();
    const response = await getFilesApi(firmId, token);
    return response.result;
  };

  const {
    execute: executeGetNonQueuedFiles,
    status: executeGetNonQueuedFilesStatus,
    value: getNonQueuedFilesValue,
    errors: getNonQueuedFilesErrors,
  } = useAsync<Array<FileType>>(getFiles, false);

  const getOrderFilesDetails = async (): Promise<OrderFileDetailsType> => {
    const token = await getAccessToken();
    const result = await getOrderFileDetailsApi(orderId, token);
    result?.openFiles.forEach((file) => {
      file.fileStatus ||= 'In Process';
      file.status = file.fileStatus;
      file.policyNumbersSearch = file.policyNumbers?.join(',');
    });
    return result;
  };

  const {
    execute: executeGetOrderFilesDetails,
    status: executeGetOrderFilesDetailsStatus,
    value: getOrderFilesDetailsValue,
    errors: executeGetOrderFilesDetailsErrors,
  } = useAsync<OrderFileDetailsType>(getOrderFilesDetails, false);

  const refreshFilesInTabAfterDrawerClose = () => {
    if (allFilesTabSelected) {
      executeGetNonQueuedFiles();
    } else if (orderQueueTabSelected) {
      executeGetOrderFilesDetails();
    }
  };

  const enqueueFiles = async (fileIds: string[]): Promise<OrderFileQueueResponseType> => {
    const token = await getAccessToken();
    const payload: OrderFilesRequestType = {
      orderId,
      fileIds,
      fileStatusId: FileStatus['In Process'],
    };
    const response = await enqueueFilesApi(orderId, payload, token);
    return response;
  };

  const {
    execute: executeEnqueueFiles,
    status: enqueueFilesStatus,
    errors: enqueueFilesErrors,
  } = useAsync<OrderFileQueueResponseType>(enqueueFiles, false);

  const dequeueFiles = async (fileIds: string[]): Promise<OrderFileQueueResponseType> => {
    const token = await getAccessToken();
    const payload: OrderFilesRequestType = {
      orderId,
      fileIds,
      fileStatusId: FileStatus.Hold,
    };
    const response = await dequeueFilesApi(orderId, payload, token);
    return response;
  };

  const {
    execute: executeDequeueFiles,
    status: dequeueFilesStatus,
    errors: dequeueFilesErrors,
  } = useAsync<OrderFileQueueResponseType>(dequeueFiles, false);

  const disableSecondaryButton = enqueueFilesStatus === 'pending' || dequeueFilesStatus === 'pending';

  const getAccountInfo = async (accountNumbers: number[]): Promise<AccountInfoType[]> => {
    if (accountNumbers.length === 0) {
      return [];
    }
    const token = await getAccessToken();
    const accountInfoNumbers: AccountInfoRequestType = { accountNumbers: accountNumbers };
    return await getAccountInfoApi(accountInfoNumbers, token);
  };

  const { execute: getAccountInfoApiExecute, value: accountInfoApiResult } = useAsync<AccountInfoType[], [number[]]>(
    getAccountInfo,
    false,
  );

  const getDisplayedRowCount = () => {
    if (gridApiRef.current) return gridApiRef.current.getDisplayedRowCount();
    return 0;
  };

  const { setSelectedFilesAmount, setAccountInfoList } = useOrderInfo();

  const onSelectionChanged = useCallback(() => {
    let selectedRows = getSelectedRows();
    setSelectedRowsForTab(selectedRows);

    // always include the files in Queue whether they have been selected or not.
    if (allFilesTabSelected) {
      selectedRows = selectedRows.concat(queue);
    } else if (orderQueueTabSelected) {
      selectedRows = queue.concat(selectedFiles);
    }

    const grossTotal = calculateGrossTotal(selectedRows);
    const netTotal = calculateNetTotal(selectedRows);
    const netDifference = calculateNetDifference(netTotal, orderData?.reportedAmount || 0);
    setSelectedFilesAmount({ calculatedGross: grossTotal, calculatedNet: netTotal, netDifference: netDifference });

    const selectedAccounts = { accountNumbers: selectedRows.map((row) => row.accountNumber) };
    const accountNumbersInOrder: number[] = selectedAccounts.accountNumbers;
    const truncatedAccountNumbers = extractUniqueAccountNumbers(accountNumbersInOrder);
    getAccountInfoApiExecute(truncatedAccountNumbers);
  }, [setSelectedFilesAmount, queueFilter, queue]);

  const [isDashboardMessagePanelOpen, setIsDashboardMessagePanelOpen] = useState<boolean>(false);
  const [conversationFileNumber, setConversationFileNumber] = useState<string>();
  const [conversationPayload, setConversationPayload] = useState<GetNotesActionLog | undefined>(undefined);

  useEffect(() => {
    const selectedNodes: IRowNode[] = [];
    gridApiRef.current?.forEachNode((node) => {
      if (selectedFiles.find((selectedFile) => selectedFile.fileId === node.data.fileId)) {
        selectedNodes.push(node);
      } else if (selectedQueueFiles.find((selectedFile) => selectedFile.fileId === node.data.fileId)) {
        selectedNodes.push(node);
      }
    });
    if (selectedNodes.length > 0) {
      gridApiRef.current?.setNodesSelected({ nodes: selectedNodes, newValue: true });
    }
  }, [queueFilter]);

  useEffect(() => {
    if (accountInfoApiResult) {
      let accountInfoArray: AccountInfoType[] = [];
      accountInfoArray = accountInfoApiResult.map(
        ({
          accountNumber,
          contractualSplit,
          costCenter,
          isNationalAgentFlag,
          jurisdictionStateCode,
          remitAccountNumber,
          underwriterCode,
          accountStatus,
        }) => ({
          accountNumber,
          contractualSplit,
          costCenter,
          isNationalAgentFlag,
          jurisdictionStateCode,
          remitAccountNumber,
          underwriterCode,
          accountStatus,
        }),
      );
      setAccountInfoList(accountInfoArray);
    }
  }, [accountInfoApiResult]);

  const extractUniqueAccountNumbers = (accountNumbers: number[]): number[] => {
    // this function returns at most 5 unique account ids from the first selected rows
    const maxItems = 5;
    const uniqueAccountNumbers: number[] = Array.from(new Set(accountNumbers));
    return uniqueAccountNumbers.slice(0, maxItems);
  };

  const netAmountTotal = selectedRows?.reduce((accumulator: number, obj: FileType) => accumulator + obj['net'], 0);
  const selectedFilesText = `Selecting ${selectedRows?.length} file(s) for ${numberValueFormatter(
    netAmountTotal,
    true,
  )}`;

  const hasFutureDate = (val: { policyDate?: string }) => {
    return val?.policyDate && +new Date(val.policyDate) > +new Date();
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api;
  };

  const onFilterChanged = (): void => {
    setDisplayedRowCount(getDisplayedRowCount());
  };

  const headerCheckboxRenderer = () => {
    return <input type="checkbox" data-testid={'OrderManagement-SelectFiles-AllCheckbox'} />;
  };

  const rowCheckboxRenderer = (params: { node: RowNode }) => {
    return <input type="checkbox" data-testid={`OrderManagement-SelectFiles-Checkbox-${params.node.id}`} />;
  };

  const policyDateRenderer = (params: { value: string }) => {
    return <div>{formatDate(params.value ?? '')}</div>;
  };

  const CustomOverlayLoading = () => {
    return (
      <div className={classes.noRows}>
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const defaultColDef: ColDef = {
    flex: 2,
    floatingFilter: true,
    filter: true,
    sortable: true,
    resizable: true,
    editable: false,
    suppressMenu: true,
    filterParams: { closeOnApply: true, suppressAndOrCondition: true },
  };

  const [columnsDefinitions, setColumnsDefinitions] = useState<ColDef[]>([
    {
      field: '',
      minWidth: 40,
      maxWidth: 40,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      pinned: 'left',
      headerComponent: headerCheckboxRenderer,
      cellRenderer: rowCheckboxRenderer,
      resizable: false,
    },
    {
      colId: 'statusColumn',
      headerName: 'Status',
      field: 'status',
      minWidth: 144,
      maxWidth: 256,
      filter: 'agSetColumnFilter',
      tooltipField: 'status',
      cellRenderer: (params: ICellRendererParams) => {
        return <CellStatus status={params.value} colorsByStatus={colorsByStatus} />;
      },
    },
    {
      colId: 'reasonCodeColumn',
      headerName: 'Reason Code',
      field: 'reasonCode',
      minWidth: 144,
      maxWidth: 256,
      filter: 'agSetColumnFilter',
      tooltipField: 'reasonCode',
    },
    {
      colId: 'fileNumberColumn',
      headerName: 'File Number',
      field: 'fileNumber',
      minWidth: 144,
      maxWidth: 384,
      filter: 'agTextColumnFilter',
      tooltipField: 'fileNumber',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      cellRenderer: CellLink,
      // temporary open in new tab until we have a proper order management navigation menu
      cellRendererParams: (row: ValueFormatterParams) => ({
        linkTemplate: `/files/${row.data.fileId}/file-info`,
        openNewTab: true,
      }),
    },
    {
      colId: 'accountNumberColumn',
      headerName: 'Account Number',
      field: 'accountNumber',
      minWidth: 144,
      maxWidth: 384,
      filter: 'agTextColumnFilter',
      tooltipField: 'accountNumber',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      colId: 'propertyAddressColumn',
      headerName: 'Property Address',
      field: 'propertyAddress',
      minWidth: 256,
      filter: 'agTextColumnFilter',
      tooltipField: 'propertyAddress',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      colId: 'underwriterColumn',
      headerName: 'Underwriter',
      field: 'underwriter',
      minWidth: 144,
      filter: 'agSetColumnFilter',
      tooltipField: 'underwriter',
    },
    {
      colId: 'policyDateColumn',
      headerName: 'Policy Date',
      field: 'policyDate',
      minWidth: 144,
      maxWidth: 384,
      filter: 'agDateColumnFilter',
      filterParams: fileListFilterParams,
      cellRenderer: policyDateRenderer,
      sort: 'asc',
      tooltipValueGetter: (params: ITooltipParams) => {
        return hasFutureDate(params.data)
          ? 'Future dated policies cannot be Queued'
          : moment(params.value).format('MM/DD/YYYY');
      },
    },
    {
      colId: 'grossColumn',
      headerName: 'Gross',
      field: 'gross',
      minWidth: 144,
      maxWidth: 256,
      filter: 'agNumberColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      type: 'rightAligned',
      cellStyle: { 'justify-content': 'flex-end' },
    },
    {
      colId: 'netColumn',
      headerName: 'Net',
      field: 'net',
      minWidth: 144,
      maxWidth: 256,
      filter: 'agNumberColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      type: 'rightAligned',
      cellStyle: { 'justify-content': 'flex-end' },
    },
    {
      colId: 'policyNumbersSearchColumn',
      field: 'policyNumbersSearch',
      headerName: 'Policy Number',
      minWidth: 144,
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      field: '',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      floatingFilter: false,
      sortable: false,
      cellRenderer: CalculatorRenderer,
      cellRendererParams: (row: ValueFormatterParams) => ({
        tabName: 'order-management',
        isRemitValid: row.data.isRemitValid && row.data.isPricingValid,
      }),
      onCellClicked: (event: CellClickedEvent) => {
        handleDrawerOpen(event, 'order-management');
      },
      minWidth: 40,
      maxWidth: 40,
      cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
      tooltipValueGetter: () => 'Open Rates and Fees',
      pinned: 'right',
    },
    {
      field: '',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      floatingFilter: false,
      sortable: false,
      cellRenderer: CellMessage,
      onCellClicked: (event: CellClickedEvent) => {
        try {
          const payload: GetNotesActionLog = {
            orderId: orderId,
            fileId: `${event.data.fileId}`,
          };
          setIsDashboardMessagePanelOpen(true);
          setConversationFileNumber(event.data.fileNumber);
          setConversationPayload(payload);
        } catch (e) {
          if (e instanceof Error) {
            addGlobalMsg({
              message: e.message,
              type: 'error',
            });
          }
        }
      },
      minWidth: 40,
      maxWidth: 40,
      cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
      tooltipValueGetter: () => 'Open conversation panel',
      pinned: 'right',
    },
  ]);

  const onSortChanged = (event: SortChangedEvent) => {
    if (event.source === 'uiColumnSorted') {
      event.columns?.forEach((sortedColumn) => {
        const fileColumnConfig = columnsDefinitions.find(
          (fileColumnConfig) => fileColumnConfig.colId == sortedColumn.getColId(),
        );
        if (fileColumnConfig) {
          fileColumnConfig.sort = sortedColumn.getSort();
        }
      });
      setColumnsDefinitions([...columnsDefinitions]);
    }
  };

  const columnsConfig = useMemo(() => {
    const columnsDefinitionsClone = [...columnsDefinitions];
    const statusColumn: ColDef[] = columnsDefinitionsClone.splice(1, 1);
    const reasonCodeColumn: ColDef[] = columnsDefinitionsClone.splice(1, 1);
    const queueColumnsConfig = [
      columnsDefinitionsClone[0],
      ...statusColumn,
      ...reasonCodeColumn,
      ...columnsDefinitionsClone.slice(1),
    ];
    return { allFilesColumnsConfig: columnsDefinitionsClone, queueColumnsConfig };
  }, [columnsDefinitions]);

  const getFileListData = async (): Promise<FileType[]> => {
    const firmId = userFirm?.firmId ? Number(userFirm.firmId) : 0;
    const result = await getFiles(firmId);
    // Set default status as In Process
    result?.forEach((file) => {
      file.fileStatus ||= 'In Process';
      file.status = file.fileStatus;
      file.policyNumbersSearch = file.policyNumbers?.join(',');
    });
    setAllFiles(result);
    setDisplayedRowCount(result?.length);
    return result;
  };

  const bookOrder = async (): Promise<BookedOrderType> => {
    const token = await getAccessToken();
    return await bookOrderApi(
      {
        orderId: orderId,
        fileIds: selectedRows.map((row) => row.fileId),
        email: profile?.emailAddress ?? '',
      },
      token,
    );
  };

  const { execute: fileListExecute, value: fileListResults } = useAsync<FileType[]>(getFileListData, false);
  const {
    execute: executeBookOrder,
    status: executeBookOrderStatus,
    value: bookedOrder,
    errors: bookOrderErrors,
  } = useAsync<BookedOrderType>(bookOrder, false);

  const totalFileCount = fileListResults?.length || 0;
  const orderQueueCount = queue?.length || 0;
  const allFilesCount = totalFileCount - orderQueueCount;
  const queueFilesCount: FileCountsType = {
    allFiles: allFilesCount,
    orderQueue: orderQueueCount,
  };

  const applySearchPolicyNumberFilter = async () => {
    await gridApiRef.current?.setColumnFilterModel('policyNumbersSearchColumn', {
      filterType: 'text',
      type: 'contains',
      filter: searchPolicyNumbers,
    });
    gridApiRef.current?.onFilterChanged();
  };

  useEffect(() => {
    fileListExecute();
  }, []);

  useEffect(() => {
    if (gridApiRef.current) {
      if (displayedRowCount === 0) {
        gridApiRef.current.showNoRowsOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  }, [displayedRowCount]);

  useEffect(() => {
    if (executeBookOrderStatus === 'success') {
      setSessionData(bookedOrder);
      localStorage.removeItem('userFirm');
      history.replace('/order-management', undefined);
    }
    if (executeBookOrderStatus === 'error') {
      bookOrderErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeBookOrderStatus]);

  useEffect(() => {
    if (enqueueFilesStatus === 'success') {
      const remainingFilesAfterQueue = allFiles.filter((file) => !selectedRows.includes(file));
      setAllFiles(remainingFilesAfterQueue);
      setSelectedFiles([]);
      setQueue([...queue, ...selectedRows]);
      setDisplayedRowCount(queueFilesCount.orderQueue + selectedRows.length);
    }
    if (enqueueFilesStatus === 'error') {
      enqueueFilesErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [enqueueFilesStatus]);

  useEffect(() => {
    if (executeGetNonQueuedFilesStatus === 'success') {
      setAllFiles(getNonQueuedFilesValue ?? []);
    }
    if (executeGetNonQueuedFilesStatus === 'error') {
      getNonQueuedFilesErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeGetNonQueuedFilesStatus]);

  useEffect(() => {
    if (dequeueFilesStatus === 'success') {
      const remainingFilesAfterDequeue = queue.filter((file) => !selectedRows.includes(file));
      setQueue(remainingFilesAfterDequeue);
      setSelectedQueueFiles([]);
      setAllFiles([...allFiles, ...selectedRows]);
      setDisplayedRowCount(queueFilesCount.allFiles + selectedRows.length);
    }
    if (dequeueFilesStatus === 'error') {
      dequeueFilesErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [dequeueFilesStatus]);

  useEffect(() => {
    if (executeGetOrderFilesDetailsStatus === 'success') {
      setQueue(getOrderFilesDetailsValue?.openFiles ?? []);
      const accountNumbers = getOrderFilesDetailsValue?.openFiles?.map((file) => file.accountNumber) ?? [];
      getAccountInfoApiExecute(extractUniqueAccountNumbers(accountNumbers));
    }
    if (executeGetOrderFilesDetailsStatus === 'error') {
      executeGetOrderFilesDetailsErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeGetOrderFilesDetailsStatus]);

  useEffect(() => {
    const combinedSelectedRows = selectedFiles.concat(queue);

    const grossTotal = calculateGrossTotal(combinedSelectedRows);
    const netTotal = calculateNetTotal(combinedSelectedRows);
    const netDifference = calculateNetDifference(netTotal, orderData?.reportedAmount || 0);

    setSelectedFilesAmount({ calculatedGross: grossTotal, calculatedNet: netTotal, netDifference: netDifference });
  }, [queue]);

  useEffect(() => {
    if (gridApiRef.current) {
      applySearchPolicyNumberFilter().catch(console.error);
    }
  }, [searchPolicyNumbers]);

  useEffect(() => {
    if (targetTab === 'orderQueue') {
      setQueueFilter('orderQueue');
      executeGetOrderFilesDetails();
    }
  }, [targetTab]);

  if (!fileListResults) {
    return (
      <ContentContainer fullWidth>
        <Content>
          <CustomOverlayLoading />
        </Content>
      </ContentContainer>
    );
  }

  const hasbookOrderActivityRight = profile?.activityRights?.some(
    (activityRight) => activityRight.ActivityRightId == ActivityRights.BookOrder,
  );
  const invalidRowSelected = selectedRows.some(
    (data) => !data.isRemitValid || moment(data.policyDate).toDate() > new Date(),
  );
  const bookOrderButtonDisabled = !hasbookOrderActivityRight || invalidRowSelected;

  return (
    <div className={classes.root}>
      <div className={classes.contentWrap}>
        <div className={classes.backButton}>
          <AgentNetButton
            color="primary"
            variant="text"
            size="medium"
            startIcon={<ArrowBack />}
            onClick={() => {
              localStorage.removeItem('userFirm');
              history.replace('/order-management', undefined);
            }}
            data-testid={`${dataQaPrefix}-Back-To-All-Orders`}
          >
            All Orders
          </AgentNetButton>
        </div>
        <Box mx={3} my={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Box display="flex">
              <Typography variant="h1" component="h1" className={classes.pageHeaderTitle}>
                Order Id: {orderId}
              </Typography>
              <Box display="flex"></Box>
            </Box>
            <Box>
              <Grid container>
                <Grid item style={{ margin: pxToRem(16, 0, 16, 24), display: 'flex-end' }}>
                  <ButtonGroup>
                    {queueFilters.map((obj) => (
                      <AgentNetButton
                        key={obj?.value}
                        variant={queueFilter === obj?.value ? 'contained' : 'outlined'}
                        onClick={() => {
                          setQueueFilter(obj?.value);
                          setDisplayedRowCount(queueFilesCount[obj?.value as keyof FileCountsType]);
                        }}
                        data-testid={`${dataQaPrefix}-${[obj?.value]}`}
                        color="primary"
                        size="small"
                      >
                        {`${obj?.name} (${queueFilesCount[obj?.value as keyof FileCountsType]})`}
                      </AgentNetButton>
                    ))}
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box marginX={3} marginTop={-3} marginBottom={3}>
          <Divider />
          <Grid container>
            <Grid item xs={6}>
              <PolicyNumberSearchField
                onSearchPolicy={(searchText: string) => {
                  setSearchPolicyNumbers(searchText);
                }}
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={2}>
              <div className={classes.createNewFileBtn}>
                <AgentNetButton
                  color="primary"
                  size="small"
                  variant="outlined"
                  data-testid={`${dataQaPrefix}-Create-New-File`}
                  disabled={false}
                  onClick={() => setCreateNewFileDrawerOpen(true)}
                  hide
                  plusIcon
                >
                  Create New File
                </AgentNetButton>
              </div>
            </Grid>
          </Grid>
        </Box>
        <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
          <DataTable
            rowData={allFilesTabSelected ? allFiles : queue}
            columnDefs={allFilesTabSelected ? columnsConfig.allFilesColumnsConfig : columnsConfig.queueColumnsConfig}
            defaultColDef={defaultColDef}
            gridOptions={{ suppressRowClickSelection: true, rowSelection: 'multiple' }}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={onFilterChanged}
            animateRows={true}
            onSortChanged={onSortChanged}
            onGridReady={onGridReady}
            components={{
              customDropdownFilter: FloatingFilterComponent,
              customOverlayLoading: CustomOverlayLoading,
            }}
            loadingOverlayComponent={'customOverlayLoading'}
          />
        </div>
        <div className={clsx(classes.paddingX, classes.selectedRows)}>
          <span>
            Showing {displayedRowCount ?? getDisplayedRowCount()} of{' '}
            {queueFilesCount[queueFilter as keyof FileCountsType]} {'files'}
          </span>
          {selectedRows?.length ? selectedFilesText : null}
        </div>
        {selectedRows?.length ? (
          <BottomAppBar
            cancelLabel="Clear All"
            onCancel={() => {
              setSelectedRowsForTab([]);
              gridApiRef.current?.deselectAll();
            }}
            cancelDataQa={`${dataQaPrefix}-ClearAll`}
            {...(orderQueueTabSelected && {
              primaryActionLabel: 'Book Order',
              onPrimaryClick: () => {
                setOpenConfirmModal(true);
              },
              primaryActionDataQa: `${dataQaPrefix}-Book-Order`,
              disablePrimary: bookOrderButtonDisabled,
            })}
            secondaryActionLabel={allFilesTabSelected ? 'Queue' : 'Remove from Queue'}
            disableSecondary={disableSecondaryButton}
            secondButtonLoader={disableSecondaryButton}
            onSecondaryClick={() => {
              if (allFilesTabSelected) {
                const selectedFilesToQueue = allFiles.filter((file) => selectedRows.includes(file));
                const filteredFileIds: string[] = selectedFilesToQueue.map((file) => file.fileId);
                executeEnqueueFiles(filteredFileIds);
              } else {
                const selectedFilesToRemoveFromQueue = queue.filter((file) => selectedRows.includes(file));
                const filteredFileIds: string[] = selectedFilesToRemoveFromQueue.map((file) => file.fileId);
                executeDequeueFiles(filteredFileIds);
              }
            }}
            secondaryActionDataQa={`${dataQaPrefix}-Modify-Assignments`}
            thirdActionLabel={!allFilesTabSelected ? 'Modify Assignments' : ''}
            disableThird={disableSecondaryButton}
            thirdButtonLoader={disableSecondaryButton}
            onThirdClick={() => {
              for (const selectedQueueFile of selectedQueueFiles) {
                selectedQueueFile.orderId = orderId;
                selectedQueueFile.status = selectedQueueFile.fileStatus;
                selectedQueueFile.fullName = selectedQueueFile.assignedTo && getFullName(selectedQueueFile);
              }
              setEditAssignmentData(selectedQueueFiles);
              setEditAssignmentDrawerOpen(true);
            }}
            thirdActionDataQa={`${dataQaPrefix}-Modify-Assignments>`}
          />
        ) : null}
        <ReportsAndPayDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          drawerData={drawerData}
          setDrawerData={setDrawerData}
          getUpdatedData={refreshFilesInTabAfterDrawerClose}
          rateAndFeeExecute={rateAndFeeExecute}
        />
        {editAssignmentDrawerOpen && (
          <FileEditAssignmentContainer
            open={editAssignmentDrawerOpen}
            setOpen={setEditAssignmentDrawerOpen}
            data={editAssignmentData}
            showTeams={false}
          />
        )}
        {createNewFileDrawerOpen && (
          <CreateNewFileDrawer openDrawer={createNewFileDrawerOpen} setOpenDrawer={setCreateNewFileDrawerOpen} />
        )}
        <AgentNetConfirmationDialog
          disabled={executeBookOrderStatus === 'pending'}
          onConfirm={executeBookOrder}
          open={openConfirmModal}
          onDismissAction={() => {
            setOpenConfirmModal(false);
          }}
          dialogTitle="Book Order"
          dialogBtnContent="Yes, Continue"
          dialogText={`Book ${selectedRows?.length} file(s) for ${numberValueFormatter(
            selectedRows.reduce((acc, row) => acc + row.net, 0),
            true,
          )}. Do you want to continue?`}
          qaAttrPrefix={'${dataQaPrefix}-Book-Order-Confirmation-'}
        />
        {conversationFileNumber && conversationPayload && (
          <ConversationDrawer
            conversationPayload={conversationPayload}
            conversationFileNumber={conversationFileNumber}
            onClose={() => setConversationPayload(undefined)}
            isDashboardMessagePanelOpen={isDashboardMessagePanelOpen}
            readOnly={false}
          />
        )}
      </div>
    </div>
  );
};

export default SelectFiles;
