import { useAuth } from '@agentnet/auth';
import {
  DrawerComponent,
  DrawerContent,
  DrawerExit,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@fluentsms/agentnet-web-components';
import { Button, createStyles, IconButton, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import { AttachFile, Delete as DeleteIcon, SendOutlined } from '@material-ui/icons';
import {
  getDocumentContentApi,
  getNotesApi,
  uploadNotesAndDocumentsApi,
} from 'api/orderManagement/order-management-api';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { NoMessages } from 'ui-kit/components/NoMessages';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { Document, DocumentPDF, DocumentXLS } from 'ui-kit/icons/DocIcons';
import { ConversationTabPanel } from './ConversationTabPanel';
import { convertFileToBase64, downloadFile, getFileType } from './fileUtils';
import { NoteAttachmentItem } from './NoteAttachmentItem';
import {
  GetNotesActionLog,
  NotesDocumentDataType,
  NotesType,
  UploadNotesAndDocumentsRequestType,
  UploadNotesAndDocumentsResponseType,
} from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .ag-center-cols-viewport': {
        height: 220,
      },
    },
    inputContainer: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      display: 'flex',
    },
    inputMessage: {
      background: 'none',
      border: 0,
      padding: theme.spacing(0, 2),
      width: '100%',
    },
    inputSubmit: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      color: theme.palette.actionSecondary.active,
      padding: theme.spacing(0.8, 1),
    },
    tabContent: {
      height: 'calc(100% - 64px)',
      overflow: 'hidden',
      overflowY: 'auto',
    },
    selectedFile: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    discardButton: {
      color: theme.palette.error.main,
    },
    loadingClass: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      minHeight: 'calc(100vh - 400px)',
    },
  }),
);

interface ConversationDrawerProps {
  conversationPayload: GetNotesActionLog | undefined;
  conversationFileNumber: string;
  onClose: () => void;
  isDashboardMessagePanelOpen: boolean;
  readOnly: boolean;
}

export function ConversationDrawer({
  conversationPayload,
  conversationFileNumber,
  onClose,
  isDashboardMessagePanelOpen,
  readOnly,
}: ConversationDrawerProps) {
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const [tabValue, setTabValue] = useState<number>(0);
  const messageRef = useRef<HTMLInputElement>({ value: '' } as HTMLInputElement);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<{ file: File; icon: JSX.Element; uploadFileString: string } | null>(
    null,
  );
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [conversationsState, setConversationsState] = useState<Array<NotesType>>();
  const [currentDownloadFile, setCurrentDownloadFile] = useState<{ uploadFileName: string; fileName: string } | null>(
    null,
  );
  const { addGlobalMsg } = useGlobalMessages();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile } = profileCtx;

  const profileUserId = profile?.userId;
  const orderId = conversationPayload?.orderId;
  const fileId = conversationPayload?.fileId;

  function getFileIconByType(fileType: string): JSX.Element {
    if (fileType === 'pdf') {
      return <DocumentPDF />;
    }
    if (fileType === 'excel') {
      return <DocumentXLS />;
    }
    return <Document />;
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getNotesData = async (orderId: number, fileId: string): Promise<Array<NotesType>> => {
    const token = await getAccessToken();
    const response = await getNotesApi(orderId, fileId, token);
    return response;
  };

  const getDocumentData = async (documentName: string): Promise<NotesDocumentDataType> => {
    const token = await getAccessToken();
    const response = await getDocumentContentApi(documentName, token);
    return response;
  };

  const {
    execute: executeGetNotes,
    status: executeGetNotesStatus,
    value: executeGetNotesResults,
    errors: executeGetNotesErrors,
  } = useAsync<Array<NotesType>>(getNotesData, false);

  const {
    execute: executeGetDocumentData,
    status: getDocumentDataStatus,
    value: getDocumentDataResult,
    errors: getDocumentDataErrors,
  } = useAsync<NotesDocumentDataType>(getDocumentData, false);

  const isFetchingFile = getDocumentDataStatus === 'pending';

  const handleFileClick = (uploadFileName: string, fileName: string) => {
    if (!uploadFileName || isFetchingFile) return;

    setCurrentDownloadFile({ uploadFileName, fileName });
    executeGetDocumentData(uploadFileName);
  };

  const handleFileAttachClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const fileType = getFileType(file.name);
        const fileIcon = getFileIconByType(fileType);
        const uploadFileString = await convertFileToBase64(file);
        setSelectedFile({ file, icon: fileIcon, uploadFileString });
      } catch (error) {
        let message = 'Failed to process the file.';
        if (error instanceof Error) {
          message = error.message;
        }
        addGlobalMsg({
          message,
          type: 'error',
        });
      }
    }
    event.target.value = ''; // Reset the file input value
  };

  useEffect(() => {
    if (getDocumentDataStatus === 'success' && getDocumentDataResult && currentDownloadFile) {
      downloadFile(getDocumentDataResult.documentData, currentDownloadFile.fileName);
      setCurrentDownloadFile(null);
    }
  }, [getDocumentDataStatus, getDocumentDataResult]);

  useEffect(() => {
    if (getDocumentDataErrors && getDocumentDataErrors.length > 0) {
      setCurrentDownloadFile(null);
      getDocumentDataErrors.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [getDocumentDataErrors]);

  useEffect(() => {
    if (conversationPayload && isDashboardMessagePanelOpen) {
      executeGetNotes(orderId, fileId);
    }
  }, [conversationPayload, isDashboardMessagePanelOpen]);

  useEffect(() => {
    if (executeGetNotesStatus === 'success' && executeGetNotesResults) {
      setConversationsState(executeGetNotesResults);
    }
  }, [executeGetNotesStatus, executeGetNotesResults]);

  useEffect(() => {
    if (executeGetNotesErrors && executeGetNotesErrors.length > 0) {
      executeGetNotesErrors.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeGetNotesErrors]);

  useEffect(() => {
    scrollToBottom();
  }, [conversationsState]);

  const postNotesAndDocumentsData = async ({
    orderId,
    fileId,
    notes,
    fileName,
    uploadFileString,
  }: UploadNotesAndDocumentsRequestType): Promise<UploadNotesAndDocumentsResponseType> => {
    const token = await getAccessToken();
    const response = await uploadNotesAndDocumentsApi(
      {
        orderId,
        fileId,
        notes,
        fileName,
        uploadFileString,
      },
      token,
    );
    return response;
  };

  const onTabChange = (e: ChangeEvent<unknown>, newValue: number): void => {
    setTabValue(newValue);
  };

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (selectedFile || messageRef.current.value.length > 0) {
      setIsFormSubmitting(true);

      if (orderId && fileId) {
        try {
          const payload: UploadNotesAndDocumentsRequestType = {
            orderId,
            fileId,
            notes: messageRef.current.value || '',
            fileName: selectedFile?.file.name || '',
            uploadFileString: selectedFile?.uploadFileString || '',
          };

          const response = await postNotesAndDocumentsData(payload);

          if (response) {
            executeGetNotes(orderId, fileId);
            if (executeGetNotesResults) {
              setConversationsState(executeGetNotesResults);
            }
          }

          messageRef.current.value = '';

          setSelectedFile(null);
          setIsFormSubmitting(false);
        } catch (e) {
          let message = 'An unknown error occurred';

          if (e instanceof Error) {
            message = e.message;
          }

          addGlobalMsg({
            message,
            type: 'error',
          });

          setIsFormSubmitting(false);
        }
      }
    }
  };

  const renderLoading = () => (
    <div className={classes.loadingClass}>
      <LoadingSpinner status="pending" variant="circle" />
    </div>
  );

  const renderConversations = () => (
    <>
      {conversationsState?.length
        ? conversationsState.map((note, index) => {
            const hasFile = !!(note.fileNameText && note.uploadedFileNameText);
            return (
              <NoteAttachmentItem
                conversation={note}
                profileUserId={profileUserId}
                key={index}
                onFileClick={hasFile ? handleFileClick : undefined}
              />
            );
          })
        : !readOnly && <NoMessages />}
      <div ref={messagesEndRef} />
    </>
  );

  return (
    <>
      <DrawerComponent isDrawerOpen={conversationPayload ? true : false}>
        <DrawerHeader
          content={<DrawerTitle title={`File No: ${conversationFileNumber}`} />}
          contentRight={
            <DrawerExit
              onClick={() => {
                onClose();
              }}
            />
          }
          divider
          subHeader={
            <Tabs aria-label="Conversation logs" indicatorColor="primary" value={tabValue} onChange={onTabChange}>
              <Tab aria-controls="conversation-panel" id="conversation-tab" label="Conversation" />
            </Tabs>
          }
        />
        <DrawerContent>
          <ConversationTabPanel
            aria-labelledby="conversation-tab"
            id="conversation-panel"
            index={0}
            value={tabValue}
            style={{ height: '100%' }}
          >
            <div className={classes.tabContent}>
              {!executeGetNotesResults ? renderLoading() : conversationsState ? renderConversations() : null}
            </div>
            {!readOnly && (
              <form onSubmit={onFormSubmit}>
                <div className={classes.inputContainer}>
                  <input
                    className={classes.inputMessage}
                    disabled={isFormSubmitting}
                    placeholder="Type a message..."
                    ref={messageRef}
                  />
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                  {selectedFile && (
                    <div className={classes.selectedFile}>
                      {selectedFile.icon}
                      <span>{selectedFile.file.name}</span>
                      <IconButton className={classes.discardButton} onClick={() => setSelectedFile(null)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )}
                  <IconButton
                    className={classes.inputSubmit}
                    disabled={isFormSubmitting}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    type="button"
                    onClick={handleFileAttachClick}
                  >
                    <AttachFile />
                  </IconButton>
                  <IconButton
                    className={classes.inputSubmit}
                    disabled={isFormSubmitting}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    type="submit"
                  >
                    <SendOutlined />
                  </IconButton>
                </div>
              </form>
            )}
          </ConversationTabPanel>
        </DrawerContent>
        <DrawerFooter>
          <Button color="primary" variant="contained" onClick={onClose}>
            Done
          </Button>
        </DrawerFooter>
      </DrawerComponent>
    </>
  );
}
