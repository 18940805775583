import { useAuth } from '@agentnet/auth';
import {
  CellLink,
  CellMessage,
  CellStatus,
  Content,
  DataTable,
  PageHeader,
  pxToRem,
} from '@fluentsms/agentnet-web-components';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import {
  CellClickedEvent,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { AgGridReactProps } from 'ag-grid-react';
import { getFirmDetails } from 'api/onBehalfOf/api';
import { getAccountInfoApi, getOrderFileDetailsApi } from 'api/orderManagement/order-management-api';
import ReportsAndPayDrawer from 'core/layout-core/core-content/ReportsAndPayDrawer';
import { KBLinks } from 'features/constants';
import CalculatorRenderer from 'features/Remittance/CalculatorRenderer';
import { filterParams, formatDate, numberValueFormatter } from 'features/Remittance/reportsListConfig';
import useRatesAndFee from 'features/Remittance/useRatesAndFee';
import { useOrderInfo } from 'hooks/OrderInfoContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { dateTooltipValue, formatDateTime } from 'utilities/utilities';
import { ConversationDrawer } from './ConversationDrawer';
import {
  AccountInfoRequestType,
  AccountInfoType,
  ClosedFileDetailType,
  FileType,
  GetNotesActionLog,
  OrderFileDetailsType,
  OrderType,
} from './types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .ag-center-cols-container, .ag-center-cols-viewport': {
        minHeight: pxToRem(40),
      },
    },
    noRows: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: pxToRem(220),
      justifyContent: 'center',
    },
    orderDetails: {
      width: '100%',
      marginBottom: 'auto',
      paddingBottom: pxToRem(40),
    },
    orderNumber: {
      fontSize: pxToRem(17),
      marginBottom: pxToRem(20),
    },
    fileCount: {
      marginTop: pxToRem(12),
    },
  }),
);

export function ClosedOrderDetails() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ order: OrderType }>();
  const gridApiRef = useRef<GridApi<FileType> | null>(null);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};

  const orderData = location.state?.order;
  const orderId: number = orderData?.orderId || 0;

  const dataQaPrefix = 'OrderManagement-ClosedOrderDetails';

  const { setUserFirm } = profileCtx;
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const { setSelectedFilesAmount, setAccountInfoList } = useOrderInfo();
  const { drawerData, setDrawerData, openDrawer, setOpenDrawer, handleDrawerOpen, rateAndFeeExecute } = useRatesAndFee(
    profileCtx.userFirm?.firmId ?? '',
    'order-management',
  );

  const [orders, setOrders] = useState<Array<ClosedFileDetailType>>([]);
  const [orderDetails, setOrderDetails] = useState<OrderFileDetailsType>();
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const [conversationFileNumber, setConversationFileNumber] = useState<string>();
  const [conversationPayload, setConversationPayload] = useState<GetNotesActionLog | undefined>(undefined);

  const setFirmName = async (firmId: number) => {
    const token = await getAccessToken();
    const firmDetailsResults = await getFirmDetails(token, String(firmId));
    if (firmDetailsResults) {
      window.localStorage.setItem('userFirm', JSON.stringify(firmDetailsResults));
      setUserFirm && setUserFirm(firmDetailsResults);
    }
  };

  const getOrderFileDetails = async (): Promise<OrderFileDetailsType> => {
    const token = await getAccessToken();
    const response = await getOrderFileDetailsApi(orderId, token);
    return response;
  };

  const getAccountInfo = async (accountNumbers: Array<number>): Promise<Array<AccountInfoType>> => {
    if (accountNumbers.length === 0) {
      return [];
    }
    const token = await getAccessToken();
    const accountInfoNumbers: AccountInfoRequestType = { accountNumbers: accountNumbers };
    return await getAccountInfoApi(accountInfoNumbers, token);
  };

  const { execute: getAccountInfoApiExecute, value: accountInfoApiResult } = useAsync<
    Array<AccountInfoType>,
    [Array<number>]
  >(getAccountInfo, false);

  const {
    execute: executeGetOrderFileDetails,
    status: executeGetOrderFileDetailsStatus,
    value: getOrderFileDetailsValue,
    errors: getOrderFileDetailsErrors,
  } = useAsync<OrderFileDetailsType>(getOrderFileDetails, false);

  const CustomOverlayLoading = () => {
    return (
      <div className={classes.noRows}>
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const onGridReady = (params: GridReadyEvent<FileType>) => {
    gridApiRef.current = params.api;
  };

  const onFilterChanged = (): void => {
    setDisplayedRowCount(getDisplayedRowCount());
  };

  const getDisplayedRowCount = () => {
    if (gridApiRef.current) return gridApiRef.current.getDisplayedRowCount();
    return 0;
  };

  const agGridConfig: AgGridReactProps = {
    columnDefs: [
      {
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) => {
          return <CellStatus status={params.value} />;
        },
        field: 'fileStatus',
        filter: 'agSetColumnFilter',
        maxWidth: 256,
        tooltipField: 'fileStatus',
      },
      {
        headerName: 'File Number',
        field: 'fileNumber',
        maxWidth: 256,
        tooltipField: 'fileNumber',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRenderer: CellLink,
        // temporary open in new tab until we have a proper order management navigation menu
        cellRendererParams: (row: ValueFormatterParams) => ({
          linkTemplate: `/files/${row.data.fileId}/file-info`,
          openNewTab: true,
        }),
        onCellClicked: (event: CellClickedEvent) => {
          setFirmName(event.data.firmId);
        },
      },
      {
        headerName: 'Account Number',
        field: 'accountNumber',
        maxWidth: 256,
        tooltipField: 'accountNumber',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Property Address',
        field: 'propertyAddress',
        minWidth: 384,
        tooltipField: 'propertyAddress',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Underwriter',
        field: 'underwriter',
        filter: 'agSetColumnFilter',
        maxWidth: 256,
        tooltipField: 'underwriter',
      },
      {
        headerName: 'Policy Date',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'policyDate',
        maxWidth: 256,
        cellRenderer: (params: ICellRendererParams) => {
          return params?.value?.length > 0 ? formatDate(params.value) : '';
        },
        tooltipValueGetter: dateTooltipValue,
      },
      {
        headerName: 'Gross',
        type: 'rightAligned',
        cellStyle: { 'justify-content': 'flex-end' },
        field: 'gross',
        filter: 'agNumberColumnFilter',
        maxWidth: 256,
        tooltipField: 'gross',
        valueFormatter: numberValueFormatter,
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Net',
        type: 'rightAligned',
        cellStyle: { 'justify-content': 'flex-end' },
        field: 'net',
        filter: 'agNumberColumnFilter',
        maxWidth: 256,
        tooltipField: 'net',
        valueFormatter: numberValueFormatter,
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        field: '',
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressHeaderFilterButton: true,
        floatingFilter: false,
        sortable: false,
        cellRenderer: CalculatorRenderer,
        cellRendererParams: () => ({
          tabName: 'order-management',
          isRemitValid: true,
        }),
        onCellClicked: (event: CellClickedEvent) => {
          handleDrawerOpen(event, 'order-management');
        },
        maxWidth: 40,
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        tooltipValueGetter: () => 'Open Rates and Fees',
        pinned: 'right',
      },
      {
        field: '',
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressHeaderFilterButton: true,
        floatingFilter: false,
        sortable: false,
        cellRenderer: CellMessage,
        onCellClicked: (event: CellClickedEvent<FileType>) => {
          try {
            const payload: GetNotesActionLog = {
              orderId: orderId,
              fileId: `${event.data?.fileId}`,
            };
            setConversationFileNumber(event.data?.fileNumber ?? '');
            setConversationPayload(payload);
          } catch (e) {
            if (e instanceof Error) {
              addGlobalMsg({
                message: e.message,
                type: 'error',
              });
            }
          }
        },
        minWidth: 40,
        maxWidth: 40,
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        tooltipValueGetter: () => 'Open conversation panel',
        pinned: 'right',
      },
    ],
    maintainColumnOrder: true,
    gridOptions: {
      getRowId: (params: GetRowIdParams<FileType>) => String(params.data.fileId),
      suppressRowClickSelection: true,
    },
    defaultColDef: {
      flex: 1,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
      suppressSizeToFit: false,
      width: 144,
      minWidth: 144,
    },
    domLayout: 'autoHeight',
    onGridReady: onGridReady,
    onFilterChanged: onFilterChanged,
  };

  const calculateGrossTotal = (orders: Array<ClosedFileDetailType>): number => {
    const grossTotal = orders.reduce((acc, order) => {
      const orderGrossTotal = order.files.reduce((fileAcc, file) => fileAcc + file.gross, 0);
      return acc + orderGrossTotal;
    }, 0);
    return parseFloat(grossTotal.toFixed(2));
  };

  const calculateNetTotal = (orders: Array<ClosedFileDetailType>): number => {
    const netTotal = orders.reduce((acc, order) => {
      const orderNetTotal = order.files.reduce((fileAcc, file) => fileAcc + file.net, 0);
      return acc + orderNetTotal;
    }, 0);
    return parseFloat(netTotal.toFixed(2));
  };

  const calculateNetDifference = (netTotal: number, reportedAmount: number): number => {
    return parseFloat((netTotal - reportedAmount).toFixed(2));
  };

  const truncatedAccountNumbers = (orders: Array<ClosedFileDetailType>): Array<number> => {
    const maxItems = 5;
    const accountNumbers = orders.reduce<number[]>((acc, order) => {
      const orderAccountNumbers = order.files.map((file) => file.accountNumber);
      return [...acc, ...orderAccountNumbers];
    }, []);
    const uniqueAccountNumbers: number[] = Array.from(new Set(accountNumbers));
    return uniqueAccountNumbers.slice(0, maxItems);
  };

  useEffect(() => {
    executeGetOrderFileDetails();
  }, []);

  useEffect(() => {
    if (executeGetOrderFileDetailsStatus === 'success') {
      const orders = getOrderFileDetailsValue?.closedFiles ?? [];
      const grossTotal = calculateGrossTotal(orders);
      const netTotal = calculateNetTotal(orders);
      const netDifference = calculateNetDifference(netTotal, getOrderFileDetailsValue?.reportedAmount || 0);
      setOrders(orders);
      getOrderFileDetailsValue && setOrderDetails(getOrderFileDetailsValue);
      setSelectedFilesAmount({ calculatedGross: grossTotal, calculatedNet: netTotal, netDifference: netDifference });
      getAccountInfoApiExecute(truncatedAccountNumbers(orders));
    }
    if (executeGetOrderFileDetailsStatus === 'error') {
      getOrderFileDetailsErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeGetOrderFileDetailsStatus]);

  useEffect(() => {
    setDisplayedRowCount(getDisplayedRowCount());
  }, [displayedRowCount]);

  useEffect(() => {
    if (accountInfoApiResult) {
      let accountInfoArray: AccountInfoType[] = [];
      accountInfoArray = accountInfoApiResult.map(
        ({
          accountNumber,
          contractualSplit,
          costCenter,
          isNationalAgentFlag,
          jurisdictionStateCode,
          remitAccountNumber,
          underwriterCode,
          accountStatus,
        }) => ({
          accountNumber,
          contractualSplit,
          costCenter,
          isNationalAgentFlag,
          jurisdictionStateCode,
          remitAccountNumber,
          underwriterCode,
          accountStatus,
        }),
      );
      setAccountInfoList(accountInfoArray);
    }
  }, [accountInfoApiResult]);

  if (!getOrderFileDetailsValue) {
    return (
      <ContentContainer fullWidth>
        <Content>
          <CustomOverlayLoading />
        </Content>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer fullWidth>
      <Content className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AgentNetButton
              color="primary"
              variant="text"
              size="medium"
              startIcon={<ArrowBack />}
              onClick={() => {
                localStorage.removeItem('userFirm');
                history.replace('/order-management', undefined);
              }}
              data-testid={`${dataQaPrefix}-Back-To-All-Orders`}
            >
              All Orders
            </AgentNetButton>
          </Grid>
          <Grid item xs={12}>
            <PageHeader
              divider={true}
              menuItems={[
                {
                  label: 'Knowledge Base',
                  link: KBLinks.home,
                },
              ]}
              title={`Order Id: ${orderId}`}
              contentRight={
                <div style={{ display: 'flex' }}>
                  <Typography variant="body2">
                    <b>Booked:</b> {orderDetails?.bookedDate ? formatDateTime(orderDetails.bookedDate) : ''} by{' '}
                    {orderDetails?.bookedUserFullName}
                  </Typography>
                </div>
              }
            />
          </Grid>
          {orders.map((order) => (
            <div key={order.orderNumber} className={classes.orderDetails}>
              <Grid item xs={12} key={order.orderNumber}>
                <Typography variant="h2" className={classes.orderNumber}>
                  Order Number: {order.orderNumber}
                </Typography>
                <DataTable {...agGridConfig} rowData={order.files} />
                <Typography variant="body2" className={classes.fileCount}>
                  Showing {displayedRowCount || order.files.length} of {order.files.length} files
                </Typography>
              </Grid>
            </div>
          ))}
        </Grid>
      </Content>
      {openDrawer && (
        <ReportsAndPayDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          drawerData={drawerData}
          setDrawerData={setDrawerData}
          rateAndFeeExecute={rateAndFeeExecute}
        />
      )}
      {conversationFileNumber && conversationPayload && (
        <ConversationDrawer
          conversationPayload={conversationPayload}
          conversationFileNumber={conversationFileNumber}
          onClose={() => setConversationPayload(undefined)}
          isDashboardMessagePanelOpen
          readOnly={true}
        />
      )}
    </ContentContainer>
  );
}
