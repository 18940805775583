import { AutocompleteMultiSelect, MultiSelectOption } from '@fluentsms/agentnet-web-components';
import { FormControlLabel, FormHelperText, Switch } from '@material-ui/core';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AgentNetCheckBox } from 'ui-kit/inputs';
import AgentNetCheckBoxGroup from 'ui-kit/inputs/CheckBoxGroup/CheckBoxGroup';
import FormDrawerComponent from '../drawer/FormDrawerComponent';
import RadioGroup from '../radios/RadioGroup';

interface OrderInsightsEmailPreferenceDrawerProps {
  onClose: () => void;
  open: boolean;
  onUpdateEmailPreferences: (value: OrderInsightsEmailPreference) => Promise<void>;
  drawerData: OrderInsightsEmailPreference;
  isActionInProgress?: boolean;
}

type EmailFor = {
  onlyMyFiles: boolean;
  offices: number[];
};

type OfficeMultiSelectOption = MultiSelectOption<'id', 'name'>;

type NotificationTypes = {
  watchListProperty: boolean;
  watchListParty: boolean;
  claimProperty: boolean;
  claimParty: boolean;
  vacant: boolean;
  ownerName: boolean;
  foreign: boolean;
  nonOwner: boolean;
  ownerAddr: boolean;
  lowMarket: boolean;
  lowAssess: boolean;
  finCEN: boolean;
  authLimit: boolean;
  sdn: boolean;
};

export type OrderInsightsEmailPreference = {
  firmId: string;
  isEnabled: boolean;
  emailFor?: EmailFor;
  notificationTypes?: NotificationTypes;
};

const OrderInsightsEmailPreferenceDrawer = ({
  onClose,
  open,
  onUpdateEmailPreferences,
  drawerData,
}: OrderInsightsEmailPreferenceDrawerProps) => {
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { preferredOffice } = profileCtx;

  const emailForOptions = useMemo(
    () => [
      { name: 'MyFiles', value: 'MyFiles', dataTestId: 'orderinsights-email-myfiles', label: 'Only My Files' },
      { name: 'Offices', value: 'Offices', dataTestId: 'orderinsights-email-offices', label: 'Select Offices' },
    ],
    [],
  );

  const emailNotificationTypeOptions = useMemo(
    () => [
      {
        name: 'watchListProperty',
        value: false,
        label: 'Potential Watchlist (Property)',
        dataTestId: 'watchListProperty',
      },
      { name: 'watchListParty', value: false, label: 'Potential Watchlist (Party)', dataTestId: 'watchListParty' },
      { name: 'claimProperty', value: false, label: 'Potential Claim (Property)', dataTestId: 'claimProperty' },
      { name: 'claimParty', value: false, label: 'Potential Claim (Party)', dataTestId: 'claimParty' },
      { name: 'vacant', value: false, label: 'Potential Vacant Land', dataTestId: 'vacant' },
      { name: 'ownerName', value: false, label: 'Potential Owner Name Mismatches', dataTestId: 'ownerName' },
      { name: 'foreign', value: false, label: 'Potential Foreign Seller', dataTestId: 'foreign' },
      { name: 'nonOwner', value: false, label: 'Potential Non-Owner Occupied Property', dataTestId: 'nonOwner' },
      { name: 'ownerAddr', value: false, label: 'Potential Owner Address Mismatches', dataTestId: 'ownerAddr' },
      { name: 'lowMarket', value: false, label: 'Potential Low Market Value', dataTestId: 'lowMarket' },
      { name: 'lowAssess', value: false, label: 'Potential Low Assessed Value', dataTestId: 'lowAssess' },
      { name: 'finCEN', value: false, label: 'Potential FinCEN GTO Match', dataTestId: 'finCEN' },
      { name: 'authLimit', value: false, label: 'Authorized Limit Exceeded', dataTestId: 'authLimit' },
      { name: 'sdn', value: false, label: 'Potential SDN Match', dataTestId: 'sDN' },
    ],
    [],
  );

  const [isEnabled, setEnable] = useState<boolean>(drawerData?.isEnabled || false);
  const [saveClicked, setSaveClicked] = useState<boolean>(false);
  const [emailFor, setEmailFor] = useState<string>(drawerData?.emailFor?.onlyMyFiles == true ? 'MyFiles' : 'Offices');

  const getSelectedEmailFor = () => {
    if (drawerData?.emailFor?.onlyMyFiles) {
      return emailForOptions[0];
    } else {
      return emailForOptions[1];
    }
    return emailForOptions[0]; // Default fallback value
  };

  const [selectedEmailFor, setSelectedEmailFor] = useState(getSelectedEmailFor());
  const [offices, setOffices] = useState<OfficeMultiSelectOption[]>([]);
  const [selectedOffices, setSelectedOffices] = useState<OfficeMultiSelectOption[]>([]);
  const [notificationTypeOptions, setNotificationTypeOptions] = useState(emailNotificationTypeOptions);

  const emailForError = useMemo(
    () => emailFor == 'Offices' && selectedOffices.length == 0,
    [emailFor, selectedOffices],
  );
  const notificationTypeError = useMemo(
    () => notificationTypeOptions.every((option) => !option.value),
    [notificationTypeOptions],
  );

  const initializePreferences = () => {
    if (drawerData.emailFor?.onlyMyFiles) {
      setEmailFor('MyFiles');
      setSelectedEmailFor(emailForOptions[0]);
    } else {
      setEmailFor('Offices');
      setSelectedEmailFor(emailForOptions[1]);
    }
    const preferredOffices = preferredOffice?.filter((ele: any) => ele.FirmID == drawerData?.firmId) || [];
    const selectedPreferredOffices = preferredOffices.filter((ele: any) => ele.IsSelected == 1);
    const officesToSet = (selectedPreferredOffices.length > 0 ? selectedPreferredOffices : preferredOffices).map(
      (office: any) => ({
        id: office.FirmLocationJurisdictionID,
        name: office.BusinessName,
      }),
    );

    setOffices(officesToSet);

    if (!drawerData.emailFor?.onlyMyFiles) {
      const selectedOffices = drawerData.emailFor?.offices
        .map((id) => officesToSet.find((office: OfficeMultiSelectOption) => office.id == id.toString()))
        .filter((office) => office !== null);
      setSelectedOffices(selectedOffices ?? []);
    }

    const updatedNotificationTypeOptions = emailNotificationTypeOptions.map((option) => ({
      ...option,
      value: drawerData.notificationTypes?.[option.name as keyof NotificationTypes] ?? false,
    }));
    setNotificationTypeOptions(updatedNotificationTypeOptions);
  };

  useEffect(() => {
    if (!open) {
      handleCloseDrawer();
      return;
    }
    setSaveClicked(false);
    if (drawerData?.isEnabled) {
      setEnable(true);
      initializePreferences();
    } else {
      setEnable(false);
    }
  }, [open]);

  useEffect(() => {
    if (isEnabled && !drawerData?.isEnabled) {
      initializePreferences();
    }
  }, [isEnabled]);

  useEffect(() => {
    if (emailFor == 'MyFiles') {
      setSelectedEmailFor(emailForOptions[0]);
    } else {
      setSelectedEmailFor(emailForOptions[1]);
    }
  }, [emailFor]);

  const handleEnable = () => {
    setEnable(!isEnabled);
  };

  const handleEmailForChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFor((event.target as HTMLInputElement).value);
  };

  const handleNotificationTypeChange = (name: string, checked: boolean) => {
    setNotificationTypeOptions((prev) =>
      name == 'SelectAll'
        ? prev.map((option) => ({ ...option, value: checked }))
        : prev.map((option) => (option.name == name ? { ...option, value: checked } : option)),
    );
  };

  const handleSave = () => {
    setSaveClicked(true);
    if (isEnabled && (notificationTypeError || emailForError)) {
      return; // Exit early if validation fails
    }

    const preferences: OrderInsightsEmailPreference = {
      firmId: drawerData.firmId,
      isEnabled,
      ...(isEnabled && {
        emailFor: {
          onlyMyFiles: emailFor === 'MyFiles',
          offices: emailFor === 'Offices' ? selectedOffices.map((office) => parseInt(office.id, 10)) : [],
        },
        notificationTypes: notificationTypeOptions.reduce((acc, option) => {
          acc[option.name as keyof NotificationTypes] = option.value;
          return acc;
        }, {} as NotificationTypes),
      }),
    };

    resetDrawer(preferences);
    onUpdateEmailPreferences(preferences);
    handleCloseDrawer();
  };

  const resetDrawer = (preferences: OrderInsightsEmailPreference) => {
    if (preferences.isEnabled) {
      if (preferences.emailFor?.onlyMyFiles) {
        setSelectedOffices([]);
      }
    } else {
      setEmailFor('MyFiles');
      setSelectedOffices([]);
      setSelectedEmailFor(emailForOptions[0]);
    }
  };

  const handleCloseDrawer = () => {
    onClose();
  };

  return (
    <FormDrawerComponent
      title="Email Preferences"
      open={open}
      primaryActionLabel={'Save'}
      onPrimaryAction={handleSave}
      onDismissAction={handleCloseDrawer}
    >
      {isEnabled && (
        <FormHelperText>
          Manage your email preferences for Order Insights to stay updated on all potential issues. Customize your email
          frequency and select notification types that matter to you.
        </FormHelperText>
      )}

      <FormControlLabel
        control={<Switch checked={isEnabled} onChange={handleEnable} name="enabled" />}
        label="Enable Email Notification"
      />
      {isEnabled && (
        <div>
          <div>
            <RadioGroup
              options={emailForOptions}
              onChange={handleEmailForChange}
              selected={selectedEmailFor}
              label="Receive emails for:"
              data-testid="order-insights-receive-email-for"
            />
            <AutocompleteMultiSelect
              label="Offices"
              options={offices}
              keyField="id"
              labelField="name"
              value={selectedOffices}
              onChange={(value) => {
                const officeOptions = value.map((option) => ({
                  id: option.id,
                  name: option.name,
                }));
                setSelectedOffices(officeOptions);
              }}
              required={emailFor == 'Offices'}
              disabled={emailFor != 'Offices'}
              testId="order-insights-email-offices-dropdown"
            />
            {emailForError && saveClicked && (
              <FormHelperText error={true}>Please choose at least one office.</FormHelperText>
            )}
          </div>
          <div>
            <AgentNetCheckBoxGroup label="Notification Types" layout="row" required={true} removeMarginTop={false}>
              <AgentNetCheckBox
                label="Select All"
                name="SelectAll"
                value={notificationTypeOptions.every((option) => option.value)}
                indeterminate={
                  notificationTypeOptions.some((option) => option.value) &&
                  !notificationTypeOptions.every((option) => option.value)
                }
                checkHandler={(checked: boolean, name: string) => handleNotificationTypeChange(name, checked)}
                data-testid="order-insights-email-offices-select-all"
              />
            </AgentNetCheckBoxGroup>
            <AgentNetCheckBoxGroup layout="twoColumn" required={true} removeMarginTop={false}>
              {notificationTypeOptions.map((option, index) => (
                <AgentNetCheckBox
                  key={index}
                  label={option.label}
                  name={option.name}
                  value={notificationTypeOptions[index].value}
                  checkHandler={(checked: boolean, name: string) => handleNotificationTypeChange(name, checked)}
                  data-testid={option.dataTestId}
                />
              ))}
              {notificationTypeError && saveClicked && (
                <FormHelperText error={true}>Please choose at least one notification type.</FormHelperText>
              )}
            </AgentNetCheckBoxGroup>
          </div>
        </div>
      )}
    </FormDrawerComponent>
  );
};

export default OrderInsightsEmailPreferenceDrawer;
