import { useAuth } from '@agentnet/auth';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { createFileApi, getPolicyInventoryApi } from 'api/orderManagement/order-management-api';
import { Office } from 'api/profile/types';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useContext, useEffect, useState } from 'react';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { AgentNetDivider } from 'ui-kit/components/dividers';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { AgentNetDropdownSelector, AgentNetTextInput } from 'ui-kit/inputs';
import { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import {
  createNewFileJacketTypeSchema,
  createNewFileTypeSchema,
} from 'utilities/validation/schemas/create-new-file-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { CreateNewFileJacket } from './CreateNewFileJacket';
import './OrderManagement.scss';
import { CreateFileRequestModel, CreateFileResponseModel, PolicyInventoryType } from './types';

export interface CreateNewFileJacketData {
  policyNumber: string;
  liabilityAmount: number;
  policyDate: string;
  reportedGross: number;
  reportedNet: number;
  rateTypeId: number;
  priorPolicyAmount?: number;
  priorPolicyDate?: string;
}

interface CreateNewFileData {
  accountNumber: string;
  fileNumber: string;
  salesPrice: number;
  loanAmount: number;
  closingDate: string;
  naicPropertyType: string;
  address1: string;
  address2: string;
  city: string;
  stateCode: string;
  zipCode: string;
  countyCode: string;
}

interface CreateNewFileDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (isOpen: boolean) => void;
}

function constructCreateNewFileJacketData(): CreateNewFileJacketData {
  return {
    policyNumber: '',
    liabilityAmount: 0,
    policyDate: '',
    reportedGross: 0,
    reportedNet: 0,
    rateTypeId: 0,
  };
}

function constructCreateNewFileData(): CreateNewFileData {
  return {
    accountNumber: '',
    fileNumber: '',
    salesPrice: 0,
    loanAmount: 0,
    closingDate: '',
    naicPropertyType: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipCode: '',
    countyCode: '',
  };
}

function findOffice(account: string, profile: ProfileContextInterface): Office | null {
  if (!profile.userFirm?.offices) {
    return null;
  }
  for (const office of profile.userFirm.offices) {
    for (const officeAccount of office.accounts) {
      if (officeAccount.accountNumber === account) {
        return office;
      }
    }
  }
  return null;
}

function getRequestDate(priorPolicyDate: string | undefined): string | undefined {
  if (!priorPolicyDate) {
    return undefined;
  }
  const ticks = Date.parse(priorPolicyDate);
  if (isNaN(ticks)) {
    return undefined;
  }
  const date = new Date(ticks);
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    date.getDate().toString().padStart(2, '0')
  ); // YYYY-MM-DD
}

function getAccountNumberSelectOptions(profile: ProfileContextInterface): SelectOption[] {
  if (!profile.userFirm?.offices) {
    return [];
  }
  const accountNumberSet = new Set<string>();
  const accountNumberSelectOptions: SelectOption[] = [];
  for (const office of profile.userFirm.offices) {
    for (const account of office.accounts) {
      accountNumberSet.add(account.accountNumber);
    }
  }
  accountNumberSet.forEach((accountNumber) => {
    accountNumberSelectOptions.push({
      value: accountNumber,
      name: accountNumber,
    });
  });
  return accountNumberSelectOptions.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? -1);
}

function getNaicPropertyTypeSelectOptions(): SelectOption[] {
  return [
    { value: 'Residential (1-4 family)', name: 'Residential (1-4 family)' },
    { value: 'Non-residential', name: 'Non-residential' },
    { value: 'Commercial', name: 'Commercial' },
  ];
}

const naicPropertyTypeSelectOptions = getNaicPropertyTypeSelectOptions();

const useStyles = makeStyles(() =>
  createStyles({
    addJacketButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
    },
  }),
);

export function CreateNewFileDrawer({ openDrawer, setOpenDrawer }: CreateNewFileDrawerProps): JSX.Element {
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [jacketValidationErrors, setJacketValidationErrors] = useState<FieldValidationError[][]>([[]]);
  const [data, setData] = useState<CreateNewFileData>(constructCreateNewFileData());
  const [jackets, setJackets] = useState<CreateNewFileJacketData[]>([]);
  const [policies, setPolicies] = useState<PolicyInventoryType[]>([]);
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();

  const classes = useStyles();

  const handleCancelCreate = () => {
    setOpenDrawer(false);
  };

  const createFile = async (file: CreateFileRequestModel): Promise<CreateFileResponseModel> => {
    const token = await getAccessToken();
    return await createFileApi(file, token);
  };

  const {
    execute: executeCreateFile,
    status: createFileStatus,
    errors: createFileErrors,
    value: createFileValue,
  } = useAsync<CreateFileResponseModel>(createFile, false);

  const getPolicyInventoryDetails = async (accountNumber: string): Promise<PolicyInventoryType[]> => {
    const token = await getAccessToken();
    return await getPolicyInventoryApi(accountNumber, token);
  };

  const {
    execute: executeGetPolicyInventoryDetails,
    status: getGetPolicyInventoryDetailsStatus,
    errors: getPolicyInventoryDetailsErrors,
    value: getPolicyInventoryDetailsValue,
  } = useAsync<PolicyInventoryType[]>(getPolicyInventoryDetails, false);

  useEffect(() => {
    if (getGetPolicyInventoryDetailsStatus === 'success' && getPolicyInventoryDetailsValue) {
      setPolicies(getPolicyInventoryDetailsValue.sort((a, b) => a.itemNo.localeCompare(b.itemNo)));
    } else if (getGetPolicyInventoryDetailsStatus === 'error') {
      console.error(getPolicyInventoryDetailsErrors);
      addGlobalMsg({
        message: 'There was an error while retrieving Policies.',
        type: 'error',
      });
    }
  }, [getGetPolicyInventoryDetailsStatus]);

  useEffect(() => {
    if (createFileStatus === 'success' && createFileValue) {
      addGlobalMsg({
        message: 'Your file was created successfully.',
        type: 'success',
      });
      setOpenDrawer(false);
    } else if (createFileStatus === 'error') {
      console.error(createFileErrors);
      addGlobalMsg({
        message: 'There was an error while creating the file.',
        type: 'error',
      });
    }
  }, [createFileStatus]);

  return (
    <FormDrawerComponent
      title={'Create New File'}
      open={openDrawer}
      primaryActionLabel="Create File & Add to Queue"
      onPrimaryAction={async () => {
        const errors: FieldValidationError[] = await doValidate(data, createNewFileTypeSchema);
        if (errors) {
          setValidationErrors(errors);
        }

        const jacketErrors: FieldValidationError[][] = [];
        for (const jacket of jackets) {
          const jacketErrorsForJacket: FieldValidationError[] = await doValidate(jacket, createNewFileJacketTypeSchema);
          jacketErrors.push(jacketErrorsForJacket);
        }
        setJacketValidationErrors(jacketErrors);

        if (errors?.length || jacketErrors?.some((jacketError) => jacketError?.length)) {
          addGlobalMsg({
            message: 'There are required fields that must be filled out.',
            type: 'error',
          });
          return;
        }

        const file: CreateFileRequestModel = {
          fileNumber: data.fileNumber,
          accountNumber: Number(data.accountNumber),
          propertyType: data.naicPropertyType,
          taxCode: '',
          address: data.address1,
          cityName: data.city,
          stateCode: data.stateCode,
          zipCode: data.zipCode,
          countyName: data.countyCode,
          policyJackets: jackets.map((jacket) => {
            const jacketPieces = jacket.policyNumber.split('-');
            const itemNumber = jacketPieces[0];
            return {
              jacketPrefix: itemNumber,
              jacketSuffix: jacketPieces[1],
              policyNumber: jacket.policyNumber,
              itemNumber: itemNumber,
              liabilityAmount: jacket.liabilityAmount,
              priorPolicyDate: getRequestDate(jacket.priorPolicyDate),
              priorPolicyAmount: jacket.priorPolicyAmount,
              rateItems: [
                {
                  isSimul: false,
                  faccRateTypeCode: jacket.rateTypeId.toString(),
                  starsTaxCode: '',
                  rateFeeDescr: '',
                  endorsementSTARSItemNumber: '',
                  selectedSTARSStatCode: '',
                  calculatedAmount: jacket.reportedGross,
                  netDueAmount: jacket.reportedNet,
                  rateFeeTypeCdId: 504, // Policy
                },
              ],
            };
          }),
        };

        executeCreateFile(file);
      }}
      onDismissAction={handleCancelCreate}
      testId="scrollable-form-drawer"
      width={850}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AgentNetDropdownSelector
            label={'Account Number'}
            name={'AccountNumber'}
            qaAttribute={'Create-New-File-AccountNumber'}
            options={getAccountNumberSelectOptions(profileCtx)}
            value={data.accountNumber}
            menuOption={(accountNumber) => {
              if (accountNumber !== data.accountNumber) {
                const office = findOffice(accountNumber, profileCtx);

                data.accountNumber = accountNumber;
                data.stateCode = office?.address.state ?? '';
                data.countyCode = office?.address.county ?? '';

                setJackets([]);
                setData({ ...data });

                executeGetPolicyInventoryDetails(accountNumber);
              }
            }}
            required
            showValidation
            error={validationErrors?.some((error) => error.field === 'accountNumber')}
            errs={validationErrors}
          />
        </Grid>
        <Grid item sm={6}>
          <AgentNetTextInput
            fullWidth
            label={'File Number'}
            onBlur={(event) => {
              if (event.target.value !== data.fileNumber) {
                data.fileNumber = event.target.value;
                setData({ ...data });
              }
            }}
            required
            showValidation
            error={validationErrors?.some((error) => error.field === 'fileNumber')}
            errs={validationErrors}
            helperText={validationErrors?.some((error) => error.field === 'fileNumber') && 'File Number is required'}
          />
        </Grid>
        <Grid item sm={12}>
          <AgentNetDivider typoVariant="h3" title={'Property'} disablePadding />
        </Grid>
        <Grid item xs={6}>
          <AgentNetDropdownSelector
            label={'NAIC Property Type'}
            name={'NAIC Property Type'}
            value={data.naicPropertyType}
            menuOption={(naicPropertyType) => {
              if (naicPropertyType !== data.naicPropertyType) {
                data.naicPropertyType = naicPropertyType;
                setData({ ...data });
              }
            }}
            options={naicPropertyTypeSelectOptions}
            required
            showValidation
            error={validationErrors?.some((error) => error.field === 'naicPropertyType')}
            errs={validationErrors}
          />
        </Grid>
        <Grid item sm={6} />
        <Grid item sm={6}>
          <AgentNetTextInput
            onBlur={(event) => {
              if (event.target.value !== data.address1) {
                data.address1 = event.target.value;
                setData({ ...data });
              }
            }}
            fullWidth
            label={'Address 1'}
          />
        </Grid>
        <Grid item sm={3}>
          <AgentNetTextInput
            onBlur={(event) => {
              if (event.target.value !== data.city) {
                data.city = event.target.value;
                setData({ ...data });
              }
            }}
            fullWidth
            label={'City'}
          />
        </Grid>
        <Grid item sm={3}>
          <AgentNetTextInput
            fullWidth
            label={'State'}
            value={data.stateCode}
            disabled
            required
            showValidation
            error={validationErrors?.some((error) => error.field === 'stateCode')}
            errs={validationErrors}
          />
        </Grid>
        <Grid item sm={3}>
          <AgentNetTextInput
            fullWidth
            onBlur={(event) => {
              if (event.target.value !== data.zipCode) {
                data.zipCode = event.target.value;
                setData({ ...data });
              }
            }}
            label={'Zip'}
          />
        </Grid>
        <Grid item sm={3}>
          <AgentNetTextInput
            fullWidth
            label={'County'}
            value={data.countyCode}
            required
            showValidation
            error={validationErrors?.some((error) => error.field === 'countyCode')}
            errs={validationErrors}
          />
        </Grid>
        <Grid item sm={3} />
        <Grid item sm={6}>
          <AgentNetDivider typoVariant="h3" title={'Jackets'} disablePadding />
        </Grid>
        <Grid item sm={6}>
          <div className={classes.addJacketButton}>
            <AgentNetButton
              color="primary"
              size="small"
              variant="contained"
              onClick={() => {
                jackets.push(constructCreateNewFileJacketData());
                setJackets([...jackets]);
              }}
              disabled={false}
              plusIcon
            >
              Add Jacket
            </AgentNetButton>
          </div>
        </Grid>
        {jackets.map((jacket, index) => (
          <CreateNewFileJacket
            key={index}
            accountNumber={data.accountNumber}
            stateCode={data.stateCode}
            countyCode={data.countyCode}
            policies={policies}
            jacket={jacket}
            onChange={(key, jacket) => {
              jackets[key] = jacket;
              setJackets([...jackets]);
            }}
            validationErrors={jacketValidationErrors.length > index ? jacketValidationErrors[index] : undefined}
          />
        ))}
      </Grid>
    </FormDrawerComponent>
  );
}
