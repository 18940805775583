import { useAuth } from '@agentnet/auth';
import { FormatDateUtils, HashUtils } from '@agentnet/utils';
import {
  CellLink,
  CellMessage,
  CellStatus,
  Content,
  DashboardMetricCard,
  DataTable,
  PageHeader,
} from '@fluentsms/agentnet-web-components';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import {
  CellClickedEvent,
  FilterModel,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { AgGridReactProps } from 'ag-grid-react';
import {
  GetActionLogs,
  getServiceOrders,
  GetServiceOrdersResult,
  GetServicerOrders,
} from 'api/dashboards/service-orders';
import { KBLinks } from 'features/constants';
import { filterParams } from 'features/Remittance/reportsListConfig';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import NoResults from 'ui-kit/components/noResults/NoResults';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { dateTooltipValue } from 'utilities/utilities';
import { ServiceOrderActiveStatus, ServiceOrderDashboardSubtitle } from './constants';
import { ServiceOrderDrawer } from './ServiceOrderDrawer';
import { StatusDashboard } from './StatusDashboard';
import { TimeTracker } from './TimeTracker/TimeTracker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .ag-center-cols-viewport': {
        minHeight: 220,
      },
    },
    inputContainer: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      display: 'flex',
    },
    inputMessage: {
      background: 'none',
      border: 0,
      padding: theme.spacing(0, 2),
      width: '100%',
    },
    inputSubmit: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      color: theme.palette.actionSecondary.active,
      padding: theme.spacing(0.8, 1),
    },
    noRows: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 220,
      justifyContent: 'center',
    },
    subHeading: {
      textTransform: 'none',
    },
    tabContent: {
      height: 'calc(100% - 64px)',
      overflow: 'hidden',
      overflowY: 'auto',
    },
    contentWrap: {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minHeight: 'calc(100vh - 300px)', // Adjust the height based on the header height (e.g., 64px)
    },
    contentClass: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      width: '80%',
      height: '100%', // Ensure it takes the full height of the parent
    },
    loadingClass: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      minHeight: 'calc(100vh - 400px)', // Set minimum height to 100% of the viewport height
    },
    redDot: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: '#A52A2A',
    },
  }),
);

const CustomOverlayLoading = ({ classes }: { classes: ReturnType<typeof useStyles> }): JSX.Element => (
  <div className={classes.noRows}>
    <LoadingSpinner status="pending" className="files-container--spinner-root" />
  </div>
);

export function ServiceOrders() {
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { search } = useLocation();
  const orderParam: string | null = new URLSearchParams(search).get('order');
  const { userFirm }: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const [currentStatus, setCurrentStatus] = useState<string | null>(null);
  const [isDashboardMessagePanelOpen, setIsDashboardMessagePanelOpen] = useState<boolean>(false);
  const [serviceOrderPayload, setServiceOrderPayload] = useState<GetActionLogs | undefined>(undefined);
  const [serviceOrderNumber, setServiceOrderNumber] = useState<string>();
  const [displayedRowCount, setDisplayedRowCount] = useState<number | undefined>(undefined);
  const [selectedOrders, setSelectedOrders] = useState<
    { inProcess?: boolean; title: string; orders: GetServiceOrdersResult[] } | undefined
  >(undefined);
  const gridApiRef = useRef<GridApi | null>(null);
  const { addGlobalMsg } = useGlobalMessages();

  const firmId = userFirm?.firmId ?? '';
  const getServiceOrdersData = useCallback(
    async (args: Omit<GetServicerOrders, 'firmId'> = {}): Promise<GetServiceOrdersResult[]> => {
      const token = await getAccessToken();
      const response = await getServiceOrders(
        {
          firmId: +firmId,
          ...args,
        },
        token,
      );

      return response;
    },
    [firmId, getAccessToken],
  );

  const {
    execute: serviceOrdersExecute,
    status: serviceOrdersStatus,
    value: serviceOrdersResults,
  } = useAsync<GetServiceOrdersResult[]>(getServiceOrdersData, false);

  const onGridReady = useCallback((params: GridReadyEvent): void => {
    gridApiRef.current = params.api;
  }, []);

  const onFilterChanged = useCallback((): void => {
    if (gridApiRef.current) {
      const rowCount: number = gridApiRef.current.getDisplayedRowCount();
      setDisplayedRowCount(rowCount);
    }
  }, []);

  const onFirstDataRendered = useCallback((): void => {
    if (gridApiRef.current && orderParam) {
      const rowNode: IRowNode | undefined = gridApiRef.current.getRowNode(orderParam);

      if (rowNode) {
        const pinnedRightRowElement: Element | null = document.querySelector(
          `.ag-pinned-right-cols-container [row-id="${rowNode.id}"]`,
        );

        if (pinnedRightRowElement) {
          const cells = pinnedRightRowElement.querySelectorAll('.ag-cell');
          const lastPinnedCell: HTMLElement = cells[cells.length - 1] as HTMLElement;

          if (lastPinnedCell) {
            lastPinnedCell.click();
          }
        }
      }
    }
  }, [orderParam]);

  const onTileFilterClick = useCallback(
    (status: string): void => {
      if (gridApiRef.current) {
        const filterModel: FilterModel = gridApiRef.current.getFilterModel();
        const isStatusFilterActive = !!filterModel['Status'];

        if (isStatusFilterActive && currentStatus === status) {
          gridApiRef.current.setFilterModel(null);
          setCurrentStatus(null);
          return;
        }

        gridApiRef.current.setFilterModel({
          Status: {
            filterType: 'set',
            values: [status],
          },
        });
        gridApiRef.current.onFilterChanged();
        setDisplayedRowCount(gridApiRef.current?.getDisplayedRowCount());
        setCurrentStatus(status);
      }
    },
    [currentStatus],
  );

  const onHandleBackClick = useCallback((): void => {
    setSelectedOrders(undefined);
  }, []);

  const onRefreshHandler = useCallback((): void => {
    serviceOrdersExecute({ isETA: true });
  }, [serviceOrdersExecute]);

  useEffect(() => {
    if (userFirm?.firmId) {
      serviceOrdersExecute();
    }
  }, [userFirm?.firmId, serviceOrdersExecute]);

  useEffect(() => {
    if (gridApiRef.current) {
      if (displayedRowCount === 0) {
        gridApiRef.current.showNoRowsOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  }, [displayedRowCount]);

  const ordersMTDComplete: GetServiceOrdersResult[] = [];
  const ordersYTDComplete: GetServiceOrdersResult[] = [];
  const ordersSearching: GetServiceOrdersResult[] = [];
  const ordersExamining: GetServiceOrdersResult[] = [];
  const ordersQualityReview: GetServiceOrdersResult[] = [];
  const ordersActionRequired: GetServiceOrdersResult[] = [];
  const nonCompleteOrders: GetServiceOrdersResult[] = [];

  serviceOrdersResults?.forEach((result: GetServiceOrdersResult) => {
    if (result?.DateDelivered) {
      const deliveredDateMoment: moment.Moment = moment(result.DateDelivered);
      const isOrderInCurrentMonth: boolean = deliveredDateMoment.isSame(moment(), 'month');
      const isOrderInCurrentYear: boolean = deliveredDateMoment.isSame(moment(), 'year');

      if (
        isOrderInCurrentMonth &&
        !Object.values(ServiceOrderActiveStatus).includes(result.Status as ServiceOrderActiveStatus)
      ) {
        ordersMTDComplete.push(result);
      }
      if (
        isOrderInCurrentYear &&
        !Object.values(ServiceOrderActiveStatus).includes(result.Status as ServiceOrderActiveStatus)
      ) {
        ordersYTDComplete.push(result);
      }
    }
    switch (result.Status) {
      case ServiceOrderActiveStatus.searching:
        ordersSearching.push(result);
        break;
      case ServiceOrderActiveStatus.examining:
        ordersExamining.push(result);
        break;
      case ServiceOrderActiveStatus.qualityReview:
        ordersQualityReview.push(result);
        break;
      case ServiceOrderActiveStatus.actionRequired:
        ordersActionRequired.push(result);
        break;
    }

    if (Object.values(ServiceOrderActiveStatus).includes(result.Status as ServiceOrderActiveStatus)) {
      nonCompleteOrders.push(result);
    }
  });

  const isEmptyPage: boolean =
    !serviceOrdersResults ||
    serviceOrdersResults.length === 0 ||
    (nonCompleteOrders.length === 0 && ordersYTDComplete.length === 0);

  const agGridConfig: AgGridReactProps = {
    rowData: nonCompleteOrders ?? [],
    columnDefs: [
      {
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) => {
          return <CellStatus status={params.value} />;
        },
        field: 'Status',
        filter: 'agSetColumnFilter',
        tooltipField: 'Status',
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'File Number',
        field: 'FileNumber',
        filter: 'agTextColumnFilter',
        tooltipField: 'FileNumber',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Service Order No.',
        field: 'OrderNumber',
        filter: 'agTextColumnFilter',
        tooltipField: 'OrderNumber',
        suppressHeaderFilterButton: true,
        cellRenderer: CellLink,
        cellRendererParams: (row: ValueFormatterParams) => ({
          linkTemplate: `/files/${HashUtils.encode(row.data.FileId)}/service-orders`,
        }),
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Office',
        field: 'OfficeName',
        filter: 'agTextColumnFilter',
        tooltipField: 'OfficeName',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        width: 256,
        minWidth: 256,
      },
      {
        headerName: 'Property State',
        field: 'PropertyState',
        filter: 'agTextColumnFilter',
        tooltipField: 'PropertyState',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Date Submitted',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'SubmittedDate',
        cellRenderer: (params: ICellRendererParams) => {
          return params?.data?.SubmittedDate?.length > 0 ? params?.data?.SubmittedDate.substring(0, 10) : '';
        },
        tooltipValueGetter: dateTooltipValue,
        sort: 'desc',
      },
      {
        headerName: 'ETA',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'ETA',
        cellRenderer: ({ value }: ICellRendererParams) => FormatDateUtils.MonthDateYear(value),
        tooltipValueGetter: dateTooltipValue,
      },
      {
        headerName: 'Transaction Type',
        field: 'TransactionType',
        filter: 'agSetColumnFilter',
        tooltipField: 'TransactionType',
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Buyer',
        field: 'Buyer',
        filter: 'agTextColumnFilter',
        tooltipField: 'Buyer',
        suppressHeaderFilterButton: true,
        width: 256,
        minWidth: 256,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Product Name',
        field: 'ProductName',
        filter: 'agTextColumnFilter',
        tooltipField: 'ProductName',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Business Segment',
        field: 'BusinessSegment',
        filter: 'agSetColumnFilter',
        tooltipField: 'BusinessSegment',
        suppressHeaderFilterButton: true,
      },
      {
        field: '',
        floatingFilter: false,
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <>
              <CellMessage />;{params.data.IsNewMessageAvailable && <span className={classes.redDot} />}
            </>
          );
        },
        onCellClicked: async (e: CellClickedEvent) => {
          try {
            const payload: GetActionLogs = {
              FileId: e.data.FileId,
              ServiceOrderReferenceId: e.data.ServiceOrderReferenceId,
            };
            setIsDashboardMessagePanelOpen(true);
            setServiceOrderNumber(e.data.OrderNumber);
            setServiceOrderPayload(payload);
            e.data.IsNewMessageAvailable = false; //Once message icon is clicked the message unread symbol must disappear
          } catch (e) {
            let message = 'An unknown error occurred';

            if (e instanceof Error) {
              message = e.message;
            }

            addGlobalMsg({
              message,
              type: 'error',
            });
          }
        },
        width: 40,
        minWidth: 40,
        tooltipValueGetter: () => 'Open conversation panel',
        pinned: 'right',
        suppressHeaderFilterButton: true,
      },
    ],
    maintainColumnOrder: true,
    gridOptions: {
      getRowId: (params: GetRowIdParams<GetServiceOrdersResult>) => String(params.data.OrderNumber),
      suppressRowClickSelection: true,
    },
    defaultColDef: {
      flex: 1,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
      suppressSizeToFit: false,
      width: 144,
      minWidth: 144,
    },
    components: {
      customOverlayLoading: () => <CustomOverlayLoading classes={classes} />,
    },
    loadingOverlayComponent: 'customOverlayLoading',
    domLayout: 'autoHeight',
    onGridReady: onGridReady,
    onFilterChanged: onFilterChanged,
    onFirstDataRendered: onFirstDataRendered,
    loading: !serviceOrdersResults,
  };

  return isEmptyPage || serviceOrdersStatus === 'pending' ? (
    <ContentContainer fullWidth>
      <Content className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader
              divider={true}
              menuItems={[
                {
                  label: 'Knowledge Base',
                  link: KBLinks.serviceOrderDashboard,
                },
              ]}
              title="Service Orders"
              subtitle={ServiceOrderDashboardSubtitle}
            />
          </Grid>
          {serviceOrdersStatus === 'pending' ? (
            <div className={classes.loadingClass}>
              <LoadingSpinner status="pending" variant="circle" />
            </div>
          ) : (
            <div className={classes.contentWrap}>
              <div className={classes.contentClass}>
                <NoResults
                  showButton={false}
                  type="files"
                  titleText={`Transform How You Track Search Order Products`}
                  pText={
                    '<p>Get real-time status updates, ETAs, and communicate directly with First American product teams—all in one place. Track your orders, export custom reports, and stay on top of your business with monthly and YTD insights.</p>' +
                    '<p>Ready to start ordering title search products from First American? Connect with your Sales Representative to get started.</p>'
                  }
                />
              </div>
            </div>
          )}
        </Grid>
      </Content>
    </ContentContainer>
  ) : (
    <ContentContainer fullWidth>
      <Content className={classes.root}>
        {!selectedOrders ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader
                contentRight={
                  <TimeTracker isUpdated={serviceOrdersStatus === 'success'} onClick={onRefreshHandler}>
                    Check For Updates
                  </TimeTracker>
                }
                divider={isEmptyPage ? true : false}
                menuItems={[
                  {
                    label: 'Knowledge Base',
                    link: KBLinks.serviceOrderDashboard,
                  },
                ]}
                title="Service Orders"
                subtitle={ServiceOrderDashboardSubtitle}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DashboardMetricCard
                dataTestId="ans-service-order-mtd-completed"
                heading="MTD - Orders Completed"
                value={`${ordersMTDComplete.length}`}
                onClick={() => setSelectedOrders({ title: 'MTD - Orders Complete', orders: ordersMTDComplete })}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DashboardMetricCard
                dataTestId="ans-service-order-ytd-completed"
                heading="YTD - Orders Completed"
                value={`${ordersYTDComplete.length}`}
                onClick={() => setSelectedOrders({ title: 'YTD - Orders Complete', orders: ordersYTDComplete })}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DashboardMetricCard
                dataTestId="ans-service-order-in-process"
                heading="Orders in Process"
                value={`${nonCompleteOrders.length}`}
                onClick={() =>
                  setSelectedOrders({ inProcess: true, title: 'Orders in Process', orders: nonCompleteOrders })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subHeading} variant="h3">
                Orders in Process
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DashboardMetricCard
                active={currentStatus === 'Searching'}
                dataTestId="ans-service-order-searching"
                heading="Searching"
                status="Searching"
                value={`${ordersSearching.length}`}
                onClick={() => onTileFilterClick('Searching')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <DashboardMetricCard
                active={currentStatus === 'Examining'}
                dataTestId="ans-service-order-examining"
                heading="Examining"
                status="Examining"
                value={`${ordersExamining.length}`}
                onClick={() => onTileFilterClick('Examining')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <DashboardMetricCard
                active={currentStatus === 'Quality Review'}
                dataTestId="ans-service-order-quality-review"
                heading="Quality Review"
                status="Quality Review"
                value={`${ordersQualityReview.length}`}
                onClick={() => onTileFilterClick('Quality Review')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <DashboardMetricCard
                active={currentStatus === 'Action Required'}
                dataTestId="ans-service-order-action-required"
                heading="Action Required"
                status="Action Required"
                value={`${ordersActionRequired.length}`}
                onClick={() => onTileFilterClick('Action Required')}
              />
            </Grid>
            <Grid item xs={12}>
              <DataTable {...agGridConfig} fixedHeader fixedBottomScroll />
              {serviceOrdersResults && (
                <Typography variant="body2">
                  Showing {displayedRowCount ?? nonCompleteOrders.length} of {serviceOrdersResults.length} orders
                </Typography>
              )}
            </Grid>
          </Grid>
        ) : (
          <StatusDashboard {...selectedOrders} onBackClick={onHandleBackClick} />
        )}
      </Content>
      {serviceOrderNumber && serviceOrderPayload && (
        <ServiceOrderDrawer
          isDashboardMessagePanelOpen={isDashboardMessagePanelOpen}
          serviceOrderPayload={serviceOrderPayload}
          serviceOrderNumber={serviceOrderNumber}
          onClose={() => setServiceOrderPayload(undefined)}
        />
      )}
    </ContentContainer>
  );
}
