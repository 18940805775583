import { ITooltipParams } from 'ag-grid-enterprise';
import { format, isAfter, isValid, parse } from 'date-fns';
import { htmlDocType, jsonDocType, msgDocType, txtDocType, xmlDocType } from 'features/constants';
import * as _ from 'lodash';
import moment from 'moment';
import { propertyType } from '../api/file/interfaces/get-file';
import { IGetLenderResponse } from '../features/files/parties/interfaces/CreateLenderRequest';
import { SelectOption } from '../ui-kit/inputs/AgentNetDropdownSelector';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const keyPress = (
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  callback: () => void,
  zipcodeEnabled?: boolean,
) => {
  if (e.key === 'Enter') {
    callback();
    e.preventDefault();
    return false;
  }

  if (zipcodeEnabled) {
    const regex = new RegExp('^[0-9-]+$');
    if (regex.test(e.key) || e.key === 'Backspace') return true;
    e.preventDefault();
    return false;
  }
};

export const dateTooltipValue = (params: ITooltipParams) => {
  const val = moment(params.value).format('MM/DD/YYYY');
  return val;
};

export const errorTooltipValue = (params: ITooltipParams) => {
  const val = params?.value?.replace('+', ',');
  return val;
};

export const isValidDate = (date: Date | null): boolean | null => {
  return date && isValid(date) && isAfter(date, new Date('1/1/1000'));
};

const FORMATS = ['MM/dd/yy', 'M/dd/yy', 'M/dd', 'MM/dd', 'M/d', 'MM/d', 'M'];
export const parseDate = (str: string, format: string): Date | null => {
  let parsedDate: Date | null = null;
  if (!str.length) {
    return null;
  }

  parsedDate = parse(str, format, new Date());
  if (!isValidDate(parsedDate)) {
    for (let i = 0; i < FORMATS.length; i++) {
      parsedDate = parse(str, FORMATS[i], new Date());
      if (isValidDate(parsedDate)) {
        return parsedDate;
      }
    }

    if (!isValidDate(parsedDate)) {
      const newDate = new Date(str);
      parsedDate = isValidDate(newDate) ? newDate : parsedDate;
    }
  }
  return parsedDate;
};

/**
 * Gets a list of SelectOptions for dropdowns from a given enum.
 * See the test for examples.
 * @param enumClass
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export function getSelectOptions(enumClass: any): SelectOption[] {
  return Object.values(enumClass).map((enumVal) => {
    const enumKey = Object.keys(enumClass)[Object.values(enumClass).indexOf(enumVal)];
    const selectOption: SelectOption = { name: enumVal as string, value: enumKey };
    return selectOption;
  });
}

/* Display Loan Amount only for <1 lenderType */
export const checkNumberOfLenderType = (lender?: IGetLenderResponse): number => {
  if (lender && lender.loanAmount) {
    return +lender.loanAmount;
  } else {
    return 0;
  }
};

export const isObjectEmpty = <T extends Record<string, unknown>>(obj: T): boolean => {
  return Object.keys(obj).length === 0;
};

export const openDocument = (pdf: string, documentType: string, windowName: string, windowFeatures: string) => {
  const byteCharacters = atob(pdf);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: documentType });
  const fileURL = URL.createObjectURL(file);
  if ([htmlDocType, xmlDocType, txtDocType, msgDocType, jsonDocType].includes(documentType)) {
    const link = document.createElement('a');
    link.href = fileURL;
    const extensionMap: { [key: string]: string } = {
      [htmlDocType]: 'html',
      [xmlDocType]: 'xml',
      [txtDocType]: 'txt',
      [msgDocType]: 'msg',
      [jsonDocType]: 'json',
    };
    const extension = extensionMap[documentType] || documentType.split('/').pop();
    link.setAttribute('download', `document.${extension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileURL);
  } else window.open(fileURL, windowName, windowFeatures);
};

export async function openPdfResponse(pdfRes: Response, fileName: string) {
  const fileBlob = await pdfRes.blob();
  //const link = document.createElement('a');
  window.open(window.URL.createObjectURL(fileBlob), '_blank');
  //link.download = `${fileName}.pdf`;
  //link.click();
  //setTimeout(() => URL.revokeObjectURL(link.href), 60000);
  //link.remove();
}

/** Returns an array of addresses for google maps. See tests for example. */
export function getMapAddressList(properties: Array<propertyType>): string[] {
  if (!properties) return [];
  const addressList: string[] = properties.map((property) => {
    const { address1, city, state, postalCode } = property;
    let address = address1 || '';
    if (address) address += ', ';
    address += city || '';
    if (city) address += ' ';
    address += state || '';
    if (state) address += ' ';
    address += postalCode || '';
    return address;
  });
  return addressList.filter((address) => address !== '');
}

export interface iErrorData {
  path: string;
  errors: string[];
}

export function errorDataToString(data: iErrorData[]): string {
  // If the data is a string, just return it
  if (typeof data === 'string') return data;

  // Handle array of iErrorData
  let errString = '';
  for (const errData of data) {
    for (const errMessage of errData.errors) {
      if (errString !== '') errString += ', ';
      errString += errMessage;
    }
  }
  return errString;
}

export const uniqueArray = (err: string[]) => Array.from(new Set([...err]));

export function getAmountWithCommas(x: any): string {
  return `$${x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export const handleError = (data: any) => {
  if (data.statusCode || data.errorCode) {
    throw { messages: data.messages, errorCode: data.statusCode || data.errorCode, data: data };
  }
};

export const handleBusinessError = (data: any) => {
  if (data.Errors && data.Errors.length > 0) {
    const errMessages = [];
    for (const errData of data.Errors) {
      const message = errData.Message;
      errMessages.push(message);
    }
    throw { messages: errMessages, errorCode: data.Errors[0].ErrorCode, data: data };
  }
};

type JsonValue = string | number | boolean | null | undefined | JsonObject;
interface JsonObject {
  [key: string]: JsonValue;
}

const toBeIgnored = ['isSdnMatched', 'region', 'uuid'];
export const removeEmpty = (obj: any): JsonValue => {
  if (_.isPlainObject(obj)) {
    return _.transform(obj as any, (result: JsonObject, value: JsonValue, key: string) => {
      const cleanedValue = removeEmpty(value);
      if (!_.isNil(cleanedValue) && cleanedValue !== '' && !toBeIgnored.includes(key)) {
        result[key] = cleanedValue;
      }
    });
  } else if (Array.isArray(obj)) {
    return _.compact(_.map(obj, removeEmpty)) as any;
  } else {
    return obj;
  }
};

export const dollarize = (str: number) => '$' + str.toLocaleString('en-US', { minimumFractionDigits: 2 });

export const hideFieldsWithStatus = ['Pending Remit', 'Remitted'];

export const calculateTotals = (products: any, hasRemittedProduct: boolean) => {
  let grossTotal = 0;
  let netTotal = 0;

  products.forEach((prod: any) => {
    const skipCalcuation = !hasRemittedProduct && hideFieldsWithStatus.includes(prod.status);
    prod.grossReported && !skipCalcuation && (grossTotal += prod.grossReported);
    prod.netReported && !skipCalcuation && (netTotal += prod.netReported);

    if (prod.additionalFees && prod.additionalFees.length) {
      prod.additionalFees.forEach((ch: any) => {
        const skipCHCalculation = !hasRemittedProduct && hideFieldsWithStatus.includes(ch.status);
        ch.grossReported && !skipCHCalculation && (grossTotal += ch.grossReported);
        ch.netReported && !skipCHCalculation && (netTotal += ch.netReported);
        if (ch.additionalFees && ch.additionalFees.length) {
          ch.additionalFees.forEach((zch: any) => {
            const skipZCHCalculation = !hasRemittedProduct && hideFieldsWithStatus.includes(zch.status);
            zch.grossReported && !skipZCHCalculation && (grossTotal += zch.grossReported);
            zch.netReported && !skipZCHCalculation && (netTotal += zch.netReported);
          });
        }
      });
    }
  });

  return { gross: grossTotal, net: netTotal };
};

export const formatDate = (dateStr: string) => {
  if (!dateStr) {
    return format(new Date(), 'MM-dd-yyyy');
  }

  const parsedDate = parse(dateStr, 'MM/dd/yyyy', new Date());
  return isValid(parsedDate) && !isNaN(parsedDate?.getTime()) ? format(parsedDate, 'MM-dd-yyyy') : undefined;
};

export const convertDatePeriodToDateRange = (period?: string): { from: string; to: string } => {
  const today = new Date();
  let from: Date;
  let to: Date = today;

  switch (period) {
    case 'today':
      from = to;
      break;
    case 'yesterday':
      from = new Date(today);
      from.setDate(today.getDate() - 1);
      to = from;
      break;
    case 'thisWeek':
      from = new Date(today);
      from.setDate(today.getDate() - today.getDay());
      to = new Date(today);
      to.setDate(from.getDate() + 6);
      break;
    case 'lastWeek':
      from = new Date(today);
      from.setDate(today.getDate() - today.getDay() - 7);
      to = new Date(from);
      to.setDate(from.getDate() + 6);
      break;
    case 'thisMonth':
      from = new Date(today.getFullYear(), today.getMonth(), 1);
      to = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      break;
    case 'lastMonth':
      from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      to = new Date(today.getFullYear(), today.getMonth(), 0);
      break;
    case 'last7Days':
      from = new Date(today);
      from.setDate(today.getDate() - 7);
      break;
    case 'last30Days':
      from = new Date(today);
      from.setDate(today.getDate() - 30);
      break;
    case 'last90Days':
      from = new Date(today);
      from.setDate(today.getDate() - 90);
      break;
    default: // today
      from = to;
      break;
  }

  const formatDate = (date: Date) =>
    `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

  return { from: formatDate(from), to: formatDate(to) };
};

// e.g. 03/02/2025 at 11:13 am
export const formatDateTime = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  return format(new Date(dateString), `MM/dd/yyyy 'at' hh:mm a`).toLowerCase();
};

export const formatNameTag = (fullName: string): string => {
  const names = fullName
    .trim()
    .split(/\s+/)
    .map((name) => name.trim());
  const firstName = names[0] ? names[0][0].toUpperCase() : '';
  const lastName = names.length > 1 ? names[names.length - 1][0].toUpperCase() : '';
  return `${firstName}${lastName}`;
};

export const getFullName = (user: { firstName?: string; middleName?: string; lastName?: string }): string => {
  const { firstName, middleName, lastName } = user;
  if (firstName && middleName && lastName) {
    return `${firstName} ${middleName} ${lastName}`.trim();
  }
  if (firstName && lastName) {
    return `${firstName} ${lastName}`.trim();
  }
  return firstName?.trim() || lastName?.trim() || '';
};
