import { useAuth } from '@agentnet/auth';
import { Grid } from '@material-ui/core';
import { format as formatDate } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { AgentNetTextInput, CurrencyField } from 'ui-kit/inputs';
import AutocompleteFirmSearch from 'ui-kit/inputs/AutocompleteFirmSearch/AutocompleteFirmSearch';
import AutocompleteSearch from 'ui-kit/inputs/AutocompleteSearch/AutocompleteSearch';
import DateFieldString from 'ui-kit/inputs/DateField/DateFieldString';

import { getFirmDetails } from 'api/onBehalfOf/api';
import { createOrderApi, firmSearchApi, getLockboxNumberApi } from 'api/orderManagement/order-management-api';

import { useOrderInfo } from 'hooks/OrderInfoContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import useSessionStorage from 'hooks/useSessionStorage';

import { orderManagementTypeSchema } from 'utilities/validation/schemas/order-management-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';

import './OrderManagement.scss';
import { FirmListType, LockboxNumberType, OrderRequestType, OrderType } from './types';

interface OrderNewContainerProps {
  openDrawer: boolean;
  setOpenDrawer: (isOpen: boolean) => void;
}

const OrderCreateContainer = ({ openDrawer, setOpenDrawer }: OrderNewContainerProps): JSX.Element => {
  const { getAccessToken } = useAuth();
  const history = useHistory();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { setUserFirm } = profileCtx;
  const { setSelectedFilesAmount, setAccountInfoList } = useOrderInfo();

  const showValidation = true;
  const defaultNoMatchesMessage = 'Enter at least 3 characters to refine search';

  const [lockbox, setLockbox] = useSessionStorage('lockboxData');
  const [firmList, setFirmList] = useState<Array<FirmListType>>(() => []);
  const [lockboxNumberList, setLockboxNumberList] = useState<Array<LockboxNumberType>>(() => []);

  const [firmSearchInput, setFirmSearchInput] = useState<string>('');
  const [myFirm, setMyFirm] = useState<FirmListType | null>(null);
  const [orderDate, setOrderDate] = useState<string | null>(null);
  const [lockboxNumber, setLockboxNumber] = useState<LockboxNumberType | null>(null);
  const [referenceNumber, setReferenceNumber] = useState<string>('');
  const [checkCount, setCheckCount] = useState<number>(0);
  const [reportedAmount, setReportedAmount] = useState<number>(0);

  const [noMatchesLabel, setNoMatchesLabel] = useState<string>(defaultNoMatchesMessage);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const { addGlobalMsg } = useGlobalMessages();

  const clearForm = () => {
    setOrderDate(null);
    setReferenceNumber('');
    setCheckCount(0);
    setReportedAmount(0);
    setValidationErrors([]);
    setLockboxNumber(null);
  };

  const clearSearch = () => {
    setFirmList([]);
    setFirmSearchInput('');
    setNoMatchesLabel(defaultNoMatchesMessage);
  };

  const handleCancelCreate = () => {
    clearSearch();
    clearForm();
    setOpenDrawer(false);
  };

  const getSelectedFirmOption = (event: number) => {
    if (firmList.length > 0) {
      const selectedFirm = firmList.find((firm) => firm.value === event);
      if (selectedFirm) {
        setMyFirm(selectedFirm);
      }
    }
  };

  useEffect(() => {
    const searchFirmsTimeout = setTimeout(async () => {
      if (firmSearchInput?.length > 2) {
        const result = await firmSearch(firmSearchInput);
        setFirmList(result);
      } else {
        setNoMatchesLabel(defaultNoMatchesMessage);
      }
    }, 500);

    return () => clearTimeout(searchFirmsTimeout);
  }, [firmSearchInput]);

  const firmSearch = async (input: string): Promise<Array<FirmListType>> => {
    const token = await getAccessToken();
    const response = await firmSearchApi({ searchText: input, isActive: true }, token);
    const result = response.result;
    const firmList: Array<FirmListType> = [];
    if (Array.isArray(result)) {
      result.forEach((firm) => {
        firmList.push({
          name: firm.firmName,
          value: firm.firmId,
          display: firm.firmName + ' (' + firm.firmId + ')',
          isActive: firm.isActive,
        });
      });
    }
    // Account Number Matching
    if (firmList.length === 1 && input.length > 5 && !isNaN(Number(input))) {
      firmList[0].accountNumber = input;
    } else if (firmList.length === 0 && input.length > 2 && !isNaN(Number(input))) {
      setNoMatchesLabel('Enter complete account number to refine search');
    } else {
      setNoMatchesLabel('No matching firms found');
    }
    return firmList;
  };

  const getLockboxNumber = async (): Promise<Array<LockboxNumberType>> => {
    if (lockbox) return lockbox;
    const token = await getAccessToken();
    const response = await getLockboxNumberApi(token);
    setLockbox(response);
    return response;
  };

  const getFilteredErrors = (errs: FieldValidationError[], fields: string[]) => {
    if (!validationErrors) {
      setValidationErrors(errs);
    } else if (errs) {
      setValidationErrors((prevErrs: FieldValidationError[]) => {
        return [...prevErrs.filter((err: { field: string }) => !fields.includes(err.field)), ...errs];
      });
    } else {
      setValidationErrors((prevErrs: FieldValidationError[]) => {
        return prevErrs.filter((err: { field: string }) => {
          return !(fields.includes(err.field) && !errs);
        });
      });
    }
  };

  const clearError = (field: string) => {
    if (validationErrors?.some((err) => err.field === field)) {
      setValidationErrors((prevErrs: FieldValidationError[]) => {
        return prevErrs.filter((err: { field: string }) => err.field !== field);
      });
    }
  };

  const setFirmName = async () => {
    const token = await getAccessToken();
    const firmDetailsResults = await getFirmDetails(token, String(myFirm?.value));
    if (firmDetailsResults) {
      window.localStorage.setItem('userFirm', JSON.stringify(firmDetailsResults));
      setUserFirm && setUserFirm(firmDetailsResults);
    }
  };

  useEffect(() => {
    const fetchLockboxNumbers = async () => {
      const result = await getLockboxNumber();
      setLockboxNumberList(result);
    };
    fetchLockboxNumbers();
  }, []);

  const createOrder = async (createOrderRequest: OrderRequestType): Promise<OrderType> => {
    const token = await getAccessToken();
    return await createOrderApi(createOrderRequest, token);
  };

  const {
    execute: executeCreateOrder,
    status: executeCreateOrderStatus,
    value: createdOrder,
    errors: createdOrderErrors,
  } = useAsync<OrderType>(createOrder, false);

  useEffect(() => {
    if (executeCreateOrderStatus === 'success') {
      // Close the drawer
      clearForm();
      setSelectedFilesAmount({ calculatedGross: 0, calculatedNet: 0, netDifference: 0 });
      setAccountInfoList([]);
      setOpenDrawer(false);
      // Set the firm name in the header
      setFirmName().then(() => {
        const myOrder = createdOrder as OrderType;
        myOrder.firm = {
          firmId: myFirm?.value || 0,
          firmName: myFirm?.name || '',
          isActive: myFirm?.isActive || false,
        };
        myOrder.lockboxNumber = {
          name: lockboxNumber?.name || '',
          value: lockboxNumber?.value || 0,
        };
        // The response needs to be history pushed along with order
        history.push('/order-management', { order: myOrder });
      });
    }

    if (executeCreateOrderStatus === 'error') {
      createdOrderErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeCreateOrderStatus]);

  return (
    <FormDrawerComponent
      title={'Create Order'}
      open={openDrawer}
      primaryActionProps={{
        loading: executeCreateOrderStatus === 'pending',
        disabled: executeCreateOrderStatus === 'pending',
      }}
      dismissActionProps={{}}
      primaryActionLabel="Select Files"
      onPrimaryAction={() => {
        doValidate(
          {
            firmSearchInput,
            orderDate,
            referenceNumber,
            checkCount,
            lockboxId: lockboxNumber?.value,
          },
          orderManagementTypeSchema,
        ).then((errs: FieldValidationError[]) => {
          getFilteredErrors(errs, ['firmSearchInput', 'orderDate', 'referenceNumber', 'checkCount', 'lockboxId']);
          if (!errs) {
            // Set user input to order object
            const orderRequest: OrderRequestType = {
              firmId: myFirm?.value || 0,
              orderDate: orderDate || '',
              lockboxId: lockboxNumber?.value || 0,
              referenceNumber: referenceNumber,
              checkCount: checkCount,
              reportedAmount: reportedAmount,
            };
            executeCreateOrder(orderRequest);
          }
        });
      }}
      onDismissAction={handleCancelCreate}
      testId="scrollable-form-drawer"
      width={600}
    >
      <Grid container spacing={3} className="create-order-form-container">
        <Grid item xs={12} className="firm-search-form">
          <AutocompleteFirmSearch
            label="Firm Name/Account No."
            name="firmSearchInput"
            data-testid={'OrderManagement-CreateOrder-FirmSearch'}
            required={true}
            hideSearchIcon
            onChange={(event) => {
              setFirmSearchInput((event.target as HTMLInputElement)?.value);
              getSelectedFirmOption(event);
              clearError('firmSearchInput');
            }}
            onBlur={(event) => {
              clearSearch();
              setFirmSearchInput((event.target as HTMLInputElement)?.value);
            }}
            options={firmList}
            noMatchesLabel={noMatchesLabel}
            error={validationErrors?.some((err) => err.field === 'firmSearchInput') && showValidation}
            errs={validationErrors}
            helperText={
              validationErrors?.some((err) => err.field === 'firmSearchInput') && showValidation && 'Firm is required'
            }
            showValidation={showValidation}
          />
        </Grid>
        <Grid item sm={6}>
          <DateFieldString
            label="Order Date"
            id="order-date"
            required={true}
            data-testid={'OrderManagement-CreateOrder-OrderDate'}
            maxDate="12/31/2100"
            value={(() => {
              const dateParsed = Date.parse(orderDate || '');
              return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : orderDate;
            })()}
            error={validationErrors?.some((err) => err.field === 'orderDate') && showValidation}
            errs={validationErrors}
            name="orderDate"
            onChange={(dateVal) => {
              setOrderDate(dateVal !== '' ? dateVal : null);
              clearError('orderDate');
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSearch
            label="Lockbox/Ticket Number"
            name="lockboxNumber"
            required={true}
            data-testid={'OrderManagement-CreateOrder-LockboxNumber'}
            hideSearchIcon
            disableFilterOptions={true}
            options={lockboxNumberList}
            noMatchesLabel=""
            onChange={(event) => {
              const selectedLockbox = lockboxNumberList.find((lockbox) => lockbox.value === event);
              if (selectedLockbox) {
                setLockboxNumber(selectedLockbox);
                clearError('lockboxId');
              } else {
                setLockboxNumber(null);
              }
            }}
            error={validationErrors?.some((err) => err.field === 'lockboxId') && showValidation}
            errs={validationErrors}
            helperText={
              validationErrors?.some((err) => err.field === 'lockboxId') && showValidation && 'Lockbox is required.'
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AgentNetTextInput
            variant="outlined"
            fullWidth
            label="Batch/Ticket Reference Number"
            name="referenceNumber"
            id="referenceNumber"
            data-testid={'OrderManagement-CreateOrder-ReferenceNumber'}
            errs={validationErrors}
            onChange={(event) => {
              setReferenceNumber(event.target.value);
              clearError('referenceNumber');
            }}
            error={validationErrors?.some((err) => err.field === 'referenceNumber') && showValidation}
            helperText={
              validationErrors?.some((err) => err.field === 'referenceNumber') &&
              showValidation &&
              'Invalid Reference Number'
            }
          ></AgentNetTextInput>
        </Grid>
        <Grid item xs={6}>
          <AgentNetTextInput
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            variant="outlined"
            fullWidth
            label="Number of Checks"
            name="checkCount"
            id="checkCount"
            data-testid={'OrderManagement-CreateOrder-NumberOfChecks'}
            errs={validationErrors}
            onChange={(event) => {
              setCheckCount(Number(event.target.value));
              clearError('checkCount');
            }}
            error={validationErrors?.some((err) => err.field === 'checkCount') && showValidation}
            showValidation={showValidation}
          ></AgentNetTextInput>
        </Grid>
        <Grid item sm={6}>
          <CurrencyField
            variant="outlined"
            fullWidth
            label={'Reported Total'}
            id="reportedTotal"
            defaultValue={0}
            name={'reportedTotal'}
            errs={validationErrors}
            max={100000000000}
            allowNegative={false}
            data-testid={'OrderManagement-CreateOrder-ReportedTotal'}
            onChange={(event) => setReportedAmount(Number(event.target.value))}
          />
        </Grid>
      </Grid>
    </FormDrawerComponent>
  );
};

export default OrderCreateContainer;
