import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React from 'react';

export interface IAgentNetCheckBoxProps extends CheckboxProps {
  label?: string;
  name?: string;
  checkHandler?: (checked: boolean, name?: any) => void;
  value: boolean;
  indeterminate?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlLabel: {
      '& .MuiTypography-root': {
        lineHeight: pxToRem(14),
      },
    },
    checkbox: {
      borderRadius: '4px',
      width: '10px',
      marginRight: theme.spacing(1),
      transition: '.2s all',
    },
    required: {
      color: theme.palette.secondary.dark,
      fontSize: pxToRem(14),
      fontWeight: 600,
    },
  }),
);

export const AgentNetCheckBox: React.FC<IAgentNetCheckBoxProps> = (props: IAgentNetCheckBoxProps) => {
  const { checkHandler, label, name, value, required, indeterminate = false, ...rest } = props;
  const classes = useStyles();
  const labelText = () => {
    if (required) {
      return (
        <>
          <span className={classes.required}>*</span>
          {label}
        </>
      );
    }
    return label;
  };
  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <Checkbox
          {...rest}
          color="primary"
          size="medium"
          checked={value}
          indeterminate={indeterminate}
          onChange={(e) => checkHandler?.(e.target.checked, name)}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          name={name}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          className={classes.checkbox}
        />
      }
      label={labelText()}
    />
  );
};
